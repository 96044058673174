import { CloseOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { colors } from '@tellonym/core/common/colorSystem'
import { capitalize } from '@tellonym/core/helpers'
import {
  DETECTABLE_LANGUAGE,
  langDetectObjectsByType1,
} from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_TARGET_GENDER } from '@tellonym/enums/lib/Tell'
import {
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Input,
  Pagination,
  Progress,
  Row,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import { clone } from 'ramda'
import React, { useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Box, ScrollView } from '../../common'
import { languageEnumAsString } from '../../common/helpers'
import { createVariances } from '../actionsV2'

const genders = [
  ARTIFICIAL_TELL_TARGET_GENDER.BOTH,
  ARTIFICIAL_TELL_TARGET_GENDER.MALE,
  ARTIFICIAL_TELL_TARGET_GENDER.FEMALE,
]

const CardHeader = ({
  group,
  groupIndex,
  groupsAmount,
  lang,
  isSavingDisabled,
  close,
  onChangePagination,
  onPressSave,
  sourceLanguage,
}) => (
  <Box>
    <Row>
      <Col xs={24} md={6}>
        {groupsAmount !== 1 && (
          <Pagination
            simple
            current={groupIndex + 1}
            defaultPageSize={1}
            total={groupsAmount}
            onChange={onChangePagination}
          />
        )}
      </Col>

      <Col align="center" xs={24} md={24} lg={12}>
        {groupsAmount !== 1 && (
          <Progress
            percent={((groupIndex + 1) / groupsAmount) * 100}
            steps={groupsAmount}
            size={groupsAmount > 32 ? 'small' : 'default'}
            showInfo={false}
          />
        )}
      </Col>

      <Col xs={24} md={6}>
        <Box flexDirection="row" justifyContent="space-between">
          {sourceLanguage && (
            <>
              <Tag color="orange">{sourceLanguage}</Tag>
              <Typography.Text> → </Typography.Text>
            </>
          )}
          <Tag color="geekblue">{lang}</Tag>
          <Button size="small" onClick={close} icon={<CloseOutlined />} />
        </Box>
      </Col>
    </Row>

    <Box flexDirection="row">
      <Box flex={1}>
        <Typography.Text type="secondary">
          Group Name -
          <Typography.Link
            disabled={
              !languageEnumAsString[
                DETECTABLE_LANGUAGE[sourceLanguage?.toUpperCase()]
              ] || !group.id
            }
            href={`/artificialtells_v2/${
              languageEnumAsString[
                DETECTABLE_LANGUAGE[sourceLanguage?.toUpperCase()]
              ]
            }/group/${group.id}`}
            target="_blank">
            {` ${group.id}`}
          </Typography.Link>
        </Typography.Text>

        <Typography.Text strong style={{ fontSize: 16 }}>
          {group.name}
        </Typography.Text>
        {Boolean(group.description) && (
          <>
            <Typography.Text type="secondary">Description</Typography.Text>
            <Typography.Text>{group.description}</Typography.Text>
          </>
        )}
      </Box>
      <Box justifyContent="space-between" alignItems="flex-end" paddingTop={8}>
        <Tooltip title="Hotkeys: tab or arrow down/up to navigate, enter to select, cmd + enter to save.">
          <InfoCircleOutlined
            style={{ fontSize: 20, color: colors.grey[5], marginBottom: 12 }}
          />
        </Tooltip>
        <Button
          type="primary"
          onClick={onPressSave}
          disabled={isSavingDisabled}>
          Save
        </Button>
      </Box>
    </Box>
  </Box>
)

const ItemExisting = ({ text }) => (
  <Box>
    <Typography.Text>{text}</Typography.Text>
    <Divider style={{ marginTop: 8, marginBottom: 8 }} />
  </Box>
)

const ItemGenerated = ({
  index,
  gender,
  variance,
  setVariance,
  setAccepted,
  isAccepted,
  onKeyDown,
  inputRef,
}) => {
  const onChange = (e) => {
    setVariance(gender, index, e.target.value)
  }

  const onToggleAccept = (e) => {
    if (typeof e === 'object') {
      // Checkbox event
      if (e.target.checked) {
        setAccepted(gender, index, true)
      } else {
        setAccepted(gender, index, false)
      }
    } else {
      // Direct toggle
      setAccepted(gender, index, !isAccepted)
    }
  }

  return (
    <Box flexDirection="row" alignItems="center" gap={8} paddingVertical={2}>
      <Input.TextArea
        ref={inputRef}
        autoSize
        disabled={isAccepted}
        bordered={false}
        showCount={false}
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={variance}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.025)',
          borderWidth: 1,
          borderStyle: 'dotted',
          borderColor: 'rgba(0, 0, 0, 0.5)',
        }}
      />
      <Checkbox
        name="isAccepted"
        checked={isAccepted}
        onChange={onToggleAccept}
      />
    </Box>
  )
}

const getGenderMap = () => ({
  [ARTIFICIAL_TELL_TARGET_GENDER.BOTH]: [],
  [ARTIFICIAL_TELL_TARGET_GENDER.MALE]: [],
  [ARTIFICIAL_TELL_TARGET_GENDER.FEMALE]: [],
})

export const VarianceGenerationBody = ({
  closeModal,
  currentGroupIndex,
  group,
  groupsAmount,
  isLoading,
  language,
  onChangePagination: onChangePaginationParent,
  onPressRegenerate,
  generatedVariances,
  genderIsLoadingMap,
  existingVariances,
  sourceLanguage,
  footer,
}) => {
  const dispatch = useDispatch()

  /**
   * We store potentially modified variances under the same index as the original
   * translations.
   */
  const [variances, setVariances] = React.useState(getGenderMap())
  const [acceptedVariances, setAcceptedVariances] = React.useState(
    getGenderMap()
  )

  const clearVariances = () => {
    setVariances(getGenderMap())
    setAcceptedVariances(getGenderMap())
  }

  const setAccepted = (gender, index, isAccepted) => {
    setAcceptedVariances((acceptedVariances) => {
      const newAcceptedVariances = [...acceptedVariances[gender]]
      newAcceptedVariances[index] = isAccepted

      return {
        ...acceptedVariances,
        [gender]: newAcceptedVariances,
      }
    })
  }

  const setVariance = (gender, index, text) => {
    setVariances((variances) => {
      const newVariances = [...variances[gender]]
      newVariances[index] = text

      return {
        ...variances,
        [gender]: newVariances,
      }
    })
  }

  const hasVariances = genders.some((gender) =>
    variances[gender].some((content) => Boolean(content))
  )

  const hasSelectedVariances = genders.some((gender) =>
    variances[gender].some(
      (content, index) => Boolean(content) && acceptedVariances[gender][index]
    )
  )

  const isSavingDisabled = !hasVariances || !hasSelectedVariances

  const langString = React.useMemo(() => {
    const langString =
      DETECTABLE_LANGUAGE[langDetectObjectsByType1[language]] ?? ''

    return capitalize(langString?.toLowerCase())
  }, [language])

  const sourceLangString = React.useMemo(() => {
    const langString = DETECTABLE_LANGUAGE[sourceLanguage]

    if (!langString) {
      return undefined
    }

    return capitalize(langString?.toLowerCase())
  }, [sourceLanguage])

  const onChangePagination = (page) => {
    onChangePaginationParent(page)
    clearVariances()
  }

  const onPressSave = () => {
    dispatch(
      createVariances({
        variances: genders.reduce(
          (acc, gender) =>
            acc.concat(
              variances[gender]
                .filter(
                  (content, index) =>
                    Boolean(content) && acceptedVariances[gender][index]
                )
                .map((content) => ({
                  content,
                  gender,
                }))
            ),
          []
        ),
        groupId: group.id,
      })
    )

    onChangePagination(currentGroupIndex + 2) // Using + 2 since the function expects to get the displayed value which is index + 1
  }

  const onPressNext = () => {
    onChangePagination(currentGroupIndex + 2) // Using + 2 since the function expects to get the displayed value which is index + 1
  }

  React.useEffect(() => {
    if (generatedVariances) {
      setVariances((variances) => {
        const newVariances = clone(variances)

        genders.forEach((gender) => {
          generatedVariances[gender].forEach((text, index) => {
            if (!acceptedVariances[gender][index]) {
              newVariances[gender][index] = text
            }
          })
        })

        return newVariances
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generatedVariances])

  const inputRefs = useRef({})
  const currentFocusRef = useRef({ gender: null, index: null })

  const focusInput = (gender, index) => {
    const key = `${gender}-${index}`
    inputRefs.current[key]?.focus()
    currentFocusRef.current = { gender, index }
  }

  const handleKeyDown = (gender, index, e) => {
    // Add cmd/ctrl + enter handler for saving
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault()
      if (!isSavingDisabled) {
        onPressSave()
      }
      return
    }

    if (e.key === 'Enter') {
      e.preventDefault()
      setAccepted(gender, index, !acceptedVariances[gender][index])

      // Find next available input after accepting
      const allInputs = genders.flatMap((g) =>
        generatedVariances[g].map((_, i) => ({ gender: g, index: i }))
      )
      const currentIndex = allInputs.findIndex(
        (item) => item.gender === gender && item.index === index
      )

      // Look for next non-accepted input
      let nextInput = null
      for (let i = currentIndex + 1; i < allInputs.length; i++) {
        const input = allInputs[i]
        if (!acceptedVariances[input.gender][input.index]) {
          nextInput = input
          break
        }
      }

      if (nextInput) {
        focusInput(nextInput.gender, nextInput.index)
      }
    } else if (e.key === 'Tab' || e.key === 'ArrowDown') {
      e.preventDefault()
      // Find next input
      const allInputs = genders.flatMap((g) =>
        generatedVariances[g].map((_, i) => ({ gender: g, index: i }))
      )
      const currentIndex = allInputs.findIndex(
        (item) => item.gender === gender && item.index === index
      )

      // Look for next non-accepted input
      let nextInput = null
      for (let i = currentIndex + 1; i < allInputs.length; i++) {
        const input = allInputs[i]
        if (!acceptedVariances[input.gender][input.index]) {
          nextInput = input
          break
        }
      }

      if (nextInput) {
        focusInput(nextInput.gender, nextInput.index)
      }
    } else if (e.key === 'ArrowUp') {
      e.preventDefault()
      // Find previous input
      const allInputs = genders.flatMap((g) =>
        generatedVariances[g].map((_, i) => ({ gender: g, index: i }))
      )
      const currentIndex = allInputs.findIndex(
        (item) => item.gender === gender && item.index === index
      )

      // Look for previous non-accepted input
      let prevInput = null
      for (let i = currentIndex - 1; i >= 0; i--) {
        const input = allInputs[i]
        if (!acceptedVariances[input.gender][input.index]) {
          prevInput = input
          break
        }
      }

      if (prevInput) {
        focusInput(prevInput.gender, prevInput.index)
      }
    }
  }

  // Add this effect to focus first input when data loads
  useEffect(() => {
    if (generatedVariances && !isLoading) {
      // Find first available input
      for (const gender of genders) {
        if (generatedVariances[gender]?.length > 0) {
          focusInput(gender, 0)
          break
        }
      }
    }
  }, [generatedVariances, isLoading, currentGroupIndex])

  if (!group.id) {
    return null
  }

  return (
    <Box transparent flex={1}>
      <Card
        loading={!generatedVariances && isLoading}
        size="small"
        title={
          <CardHeader
            close={closeModal}
            group={group}
            groupsAmount={groupsAmount}
            groupIndex={currentGroupIndex}
            isSavingDisabled={isSavingDisabled}
            lang={langString}
            onChangePagination={onChangePagination}
            onPressSave={onPressSave}
            sourceLanguage={sourceLangString}
          />
        }
        bodyStyle={{
          /* Manually measured height of header & footer + modal padding (2*24) + padding for top and bottom (2*12) */
          height: window.innerHeight - (118 + 132 + 48 + 24),
        }}>
        <ScrollView style={{ height: '100%' }}>
          {genders.map((gender) => (
            <Card
              key={gender}
              type="inner"
              size="small"
              title={ARTIFICIAL_TELL_TARGET_GENDER[gender]}
              style={{ flex: 1 }}
              extra={
                <a
                  // eslint-disable-next-line react/jsx-no-script-url
                  href="javascript:void(0)"
                  onClick={() => {
                    onPressRegenerate(gender)
                  }}>
                  Regenerate
                </a>
              }>
              <Card.Grid hoverable={false} style={{ width: '50%' }}>
                {existingVariances?.[gender]?.map((text) => (
                  <ItemExisting key={text} text={text} />
                ))}
              </Card.Grid>
              <Card.Grid hoverable={false} style={{ width: '50%' }}>
                <Spin spinning={genderIsLoadingMap[gender]}>
                  {generatedVariances?.[gender]?.map((text, index) => (
                    <ItemGenerated
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      gender={gender}
                      index={index}
                      isAccepted={acceptedVariances[gender][index] ?? false}
                      setAccepted={setAccepted}
                      setVariance={setVariance}
                      variance={variances[gender][index]}
                      inputRef={(el) => {
                        inputRefs.current[`${gender}-${index}`] = el
                      }}
                      onKeyDown={(e) => handleKeyDown(gender, index, e)}
                    />
                  ))}
                </Spin>
              </Card.Grid>
            </Card>
          ))}
        </ScrollView>
      </Card>

      <Box transparent paddingTop={8}>
        {footer}
        <Box transparent flexDirection="row" justifyContent="flex-end">
          <Button onClick={onPressNext} style={{ marginRight: 8 }}>
            {currentGroupIndex === groupsAmount - 1 ? 'Close' : 'Skip'}
          </Button>
          <Button
            type="primary"
            onClick={onPressSave}
            disabled={isSavingDisabled}>
            Save
          </Button>
        </Box>
      </Box>
    </Box>
  )
}
