import { useQueryClient } from '@tanstack/react-query'
import { events } from '@tellonym/core/events'
import {
  ARTIFICIAL_TELL_STATUS,
  ARTIFICIAL_TELL_TARGET_GENDER,
} from '@tellonym/enums/lib/Tell'
import { Grid, message } from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { AntdModal } from '../../common'
import { refreshGroup, refreshTopic } from '../actionsV2'
import {
  artellv2 as artellv2QueryKeys,
  fetchSpeedTranslation,
  useSpeedTranslationQuery,
  useSpeedTranslationRegenerateMutation,
} from '../queries'
import { CREATE_VARIANCES_SUCCESS } from '../typesV2'
import { VarianceGenerationBody } from './VarianceGenerationBody'

const getGenderIsLoadingMap = () => ({
  [ARTIFICIAL_TELL_TARGET_GENDER.BOTH]: false,
  [ARTIFICIAL_TELL_TARGET_GENDER.MALE]: false,
  [ARTIFICIAL_TELL_TARGET_GENDER.FEMALE]: false,
})

/**
 * This modal receives either one groupId or a topicId and gets all the eligible groups from it.
 */
const ModalNotLocalizedSpeedTranslationComponent = ({
  closeModal,
  language,
  groupId,
  topicId,
  updateProps,
}) => {
  const dispatch = ReactRedux.useDispatch()
  const screens = Grid.useBreakpoint()
  const queryClient = useQueryClient()

  const [currentGroupIndex, setCurrentGroupIndex] = React.useState(0)

  const [genderIsLoadingMap, setGenderIsLoadingMap] = React.useState(
    getGenderIsLoadingMap()
  )

  const allGroups = ReactRedux.useSelector(
    (state) => state.artificialTellsV2.topicDetails?.[topicId]?.groups ?? []
  )

  const filteredGroups = allGroups.filter(
    (group) => group.status === ARTIFICIAL_TELL_STATUS.NOT_LOCALIZED
  )

  const oneGroup = ReactRedux.useSelector(
    (state) => state.artificialTellsV2.groupDetails?.[groupId]
  )

  const notLocalizedGroups = React.useMemo(() => {
    if (groupId) {
      return [oneGroup]
    }

    if (filteredGroups.length > 0) {
      return filteredGroups
    }

    return allGroups
  }, [groupId, oneGroup, filteredGroups, allGroups])

  const group = notLocalizedGroups[currentGroupIndex] ?? {}

  const { data: resultVariances, isFetching } = useSpeedTranslationQuery({
    language,
    groupId: group?.id,
  })

  const { mutate: regenerateVariances } =
    useSpeedTranslationRegenerateMutation()

  const onChangePagination = (page) => {
    if (page > notLocalizedGroups.length) {
      message.success('All groups done 🎉')
      closeModal()
    } else {
      setCurrentGroupIndex(page - 1)
      setGenderIsLoadingMap(getGenderIsLoadingMap())
    }
  }

  const onPressRegenerate = (gender) => {
    setGenderIsLoadingMap((genderIsLoadingMap) => ({
      ...genderIsLoadingMap,
      [gender]: true,
    }))

    const resetIsLoading = () => {
      setGenderIsLoadingMap((genderIsLoadingMap) => ({
        ...genderIsLoadingMap,
        [gender]: false,
      }))
    }

    regenerateVariances(
      {
        language,
        groupId: group.id,
        gender,
        prevResponseQuestions: resultVariances?.translated[gender],
      },
      {
        onSettled: resetIsLoading,
      }
    )
  }

  React.useEffect(() => {
    updateProps({
      width: screens?.xxl ? '65%' : '75%',
      style: { top: 12 },
    })
  }, [screens])

  /**
   * Shows a success message when the variances are created and updates the topic so the user can see the changes
   */
  React.useEffect(() => {
    const subscription = events.addListener(CREATE_VARIANCES_SUCCESS, () => {
      message.success('Variances created successfully')
    })

    if (!group.id) {
      message.info('No groups found to translate')

      closeModal()
    }

    return () => {
      events.clearListener(subscription)

      if (topicId) {
        dispatch(refreshTopic({ topicId }))
      } else if (groupId) {
        dispatch(refreshGroup({ groupId }))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * Prefetches the data for the next group
   */
  React.useEffect(() => {
    if (currentGroupIndex + 1 < notLocalizedGroups.length) {
      const payload = {
        language,
        groupId: notLocalizedGroups[currentGroupIndex + 1].id,
      }

      queryClient.prefetchQuery({
        queryKey: artellv2QueryKeys.speedTranslations(payload),
        queryFn: fetchSpeedTranslation(payload),
      })
    }
  }, [currentGroupIndex, language, notLocalizedGroups, queryClient])

  if (notLocalizedGroups.length === 0) {
    return null
  }

  return (
    <VarianceGenerationBody
      closeModal={closeModal}
      currentGroupIndex={currentGroupIndex}
      existingVariances={resultVariances?.original}
      generatedVariances={resultVariances?.translated}
      genderIsLoadingMap={genderIsLoadingMap}
      group={group}
      groupsAmount={notLocalizedGroups.length}
      isLoading={isFetching}
      language={language}
      onChangePagination={onChangePagination}
      onPressRegenerate={onPressRegenerate}
      sourceLanguage={resultVariances?.sourceLanguage}
    />
  )
}

const show = (payload) =>
  AntdModal.show({
    render: (props) => (
      <ModalNotLocalizedSpeedTranslationComponent {...payload} {...props} />
    ),
    closable: false,
    maskClosable: false,
    footer: null,
  })

export const ModalNotLocalizedSpeedTranslation = { show }
