import { RingProgress } from '@ant-design/plots'
import { StatisticCard } from '@ant-design/pro-components'
import { useQueryClient } from '@tanstack/react-query'
import { events } from '@tellonym/core/events'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_QUESTION_DEPTH,
  ARTIFICIAL_TELL_TARGET_GENDER,
  ARTIFICIAL_TELL_USER_GROUP_TYPE,
} from '@tellonym/enums/lib/Tell'
import {
  Breadcrumb,
  Button,
  Divider,
  Grid,
  InputNumber,
  message,
  Typography,
} from 'antd'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { Box, BoxSecondary, history, styleSheets } from '../../common'
import { AntdModal } from '../../common/components/AntdModal'
import {
  artellv2 as artellv2QueryKeys,
  fetchSpeedTranslation,
  useAllGroupsQuery,
  useNotLocalizedGroupsQuery,
  useSpeedTranslationQuery,
  useSpeedTranslationRegenerateMutation,
} from '../queries'
import { getDataMode, getLanguage } from '../selectorsV2'
import { CREATE_VARIANCES_SUCCESS } from '../typesV2'
import { ButtonBack } from './ButtonBack'
import { DepthSelect, TypeSelect } from './PageArtificialTellsGroupDetailsV2'
import { SelectorsLanguage } from './SelectorsLanguage'
import { VarianceGenerationBody } from './VarianceGenerationBody'

const getGenderIsLoadingMap = () => ({
  [ARTIFICIAL_TELL_TARGET_GENDER.BOTH]: false,
  [ARTIFICIAL_TELL_TARGET_GENDER.MALE]: false,
  [ARTIFICIAL_TELL_TARGET_GENDER.FEMALE]: false,
})

/**
 * It takes the specified top amount of german groups and checks which of them are not localized
 * in the specified language. Those are returned ordered by answer rate desc.
 * If the language is german, it takes english as the reference language.
 */
const useMarketData = ({ amount, language, groupType, groupDepth }) => {
  const {
    data: allGermanGroups,
    isSuccess: isSuccessGermanGroups,
    isFetching: isFetchingGermanGroups,
  } = useAllGroupsQuery({
    language: language === 'de' ? 'en' : 'de',
    dataMode: ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH,
  })

  const {
    data: notLocalizedGroups,
    isSuccess: isSuccessNotLocalizedGroups,
    isFetching: isFetchingNotLocalizedGroups,
  } = useNotLocalizedGroupsQuery({
    language,
    dataMode: ARTIFICIAL_TELL_USER_GROUP_TYPE.HIGH,
  })

  const eligibleGroups = React.useMemo(() => {
    if (!allGermanGroups || !notLocalizedGroups) {
      return []
    }

    const topGermanGroupIds = allGermanGroups.groups
      .filter((group) => group.type === groupType && group.depth === groupDepth)
      .sort(
        (a, b) => b.analytics.answerRate.amount - a.analytics.answerRate.amount // answerRate descending
      )
      .slice(0, amount)
      .map((group) => group.id)

    const filteredGroups = notLocalizedGroups.groups
      .filter((group) => topGermanGroupIds.includes(group.id))
      .sort(
        (a, b) =>
          topGermanGroupIds.findIndex((id) => id === a.id) -
          topGermanGroupIds.findIndex((id) => id === b.id)
      )

    return filteredGroups
  }, [allGermanGroups, amount, groupDepth, groupType, notLocalizedGroups])

  return {
    eligibleGroups,
    isSuccess: isSuccessGermanGroups && isSuccessNotLocalizedGroups,
    isFetching: isFetchingGermanGroups || isFetchingNotLocalizedGroups,
  }
}

const Header = () => {
  return (
    <BoxSecondary padding={24}>
      <Box
        alignItems="center"
        justifyContent="space-between"
        transparent
        flexDirection="row">
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          transparent>
          <ButtonBack
            onPress={() => {
              history.goBack()
            }}
            style={{ marginRight: 12, width: 30, height: 30 }}
          />

          <Breadcrumb>
            <Breadcrumb.Item key="Not localized">
              Market Speed Translation
            </Breadcrumb.Item>
          </Breadcrumb>
        </Box>
        <SelectorsLanguage />
      </Box>

      <Box transparent marginTop={12}>
        <Typography.Text type="secondary">
          This page shows the top groups from the german market that are not
          translated in the selected language ordered by answer rate desc.
        </Typography.Text>
        <Typography.Text type="secondary">
          If german is selected, it takes english as the reference language.
        </Typography.Text>
      </Box>
    </BoxSecondary>
  )
}

const ModalMarketSpeedTranslationComponent = ({
  amountGroups,
  closeModal,
  groupType,
  groupDepth,
  updateProps,
}) => {
  const screens = Grid.useBreakpoint()
  const queryClient = useQueryClient()

  const [currentGroupIndex, setCurrentGroupIndex] = React.useState(0)
  const [genderIsLoadingMap, setGenderIsLoadingMap] = React.useState(
    getGenderIsLoadingMap()
  )

  const dataMode = ReactRedux.useSelector(getDataMode)
  const language = ReactRedux.useSelector(getLanguage)

  const { eligibleGroups } = useMarketData({
    amount: amountGroups,
    language,
    groupType,
    groupDepth,
  })

  const group = eligibleGroups[currentGroupIndex] ?? {}

  const { data: resultVariances, isFetching } = useSpeedTranslationQuery({
    language,
    groupId: group?.id,
  })

  const { mutate: regenerateVariances } =
    useSpeedTranslationRegenerateMutation()

  const onChangePagination = (page) => {
    if (page > eligibleGroups.length) {
      message.success('All groups done 🎉')
    } else {
      setCurrentGroupIndex(page - 1)
      setGenderIsLoadingMap(getGenderIsLoadingMap())
    }
  }

  const onPressRegenerate = (gender) => {
    setGenderIsLoadingMap((genderIsLoadingMap) => ({
      ...genderIsLoadingMap,
      [gender]: true,
    }))

    const resetIsLoading = () => {
      setGenderIsLoadingMap((genderIsLoadingMap) => ({
        ...genderIsLoadingMap,
        [gender]: false,
      }))
    }

    regenerateVariances(
      {
        language,
        groupId: group.id,
        gender,
        prevResponseQuestions: resultVariances?.translated[gender],
      },
      {
        onSettled: resetIsLoading,
      }
    )
  }

  React.useEffect(() => {
    updateProps({
      width: screens?.xxl ? '65%' : '75%',
      style: { top: 12 },
    })
  }, [screens])

  /**
   * Shows a success message when the variances are created and updates the topic so the user can see the changes
   */
  React.useEffect(() => {
    const subscription = events.addListener(CREATE_VARIANCES_SUCCESS, () => {
      message.success('Variances created successfully')
    })

    return () => {
      events.clearListener(subscription)

      queryClient.invalidateQueries({
        queryKey: artellv2QueryKeys.notLocalizedGroups({ language, dataMode }),
      })
    }
  }, [language, dataMode])

  /**
   * Prefetches the data for the next group
   */
  React.useEffect(() => {
    if (currentGroupIndex + 1 < eligibleGroups.length) {
      const payload = {
        language,
        groupId: eligibleGroups[currentGroupIndex + 1].id,
      }

      queryClient.prefetchQuery({
        queryKey: artellv2QueryKeys.speedTranslations(payload),
        queryFn: fetchSpeedTranslation(payload),
      })
    }
  }, [currentGroupIndex, language, eligibleGroups, queryClient])

  return (
    <VarianceGenerationBody
      closeModal={closeModal}
      currentGroupIndex={currentGroupIndex}
      existingVariances={resultVariances?.original}
      generatedVariances={resultVariances?.translated}
      genderIsLoadingMap={genderIsLoadingMap}
      group={group}
      groupsAmount={eligibleGroups.length}
      isLoading={isFetching}
      language={language}
      onChangePagination={onChangePagination}
      onPressRegenerate={onPressRegenerate}
      sourceLanguage={resultVariances?.sourceLanguage}
    />
  )
}

const show = (payload) =>
  AntdModal.show({
    render: (props) => (
      <ModalMarketSpeedTranslationComponent {...payload} {...props} />
    ),
    closable: false,
    maskClosable: false,
    footer: null,
  })

export const PageMarketSpeedTranslation = () => {
  const containerRef = React.useRef(null)

  const [amountGroups, setAmountGroups] = React.useState(500)
  const [groupDepth, setGroupDepth] = React.useState(
    ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO
  )
  const [groupType, setGroupType] = React.useState(
    ARTIFICIAL_TELL_GROUP_TYPES.OPEN
  )

  const language = ReactRedux.useSelector(getLanguage)

  const {
    eligibleGroups,
    isSuccess,
    isFetching: isFetchingGroups,
  } = useMarketData({
    amount: amountGroups,
    language,
    groupType,
    groupDepth,
  })

  const percent =
    isFetchingGroups && !isSuccess
      ? 0
      : (amountGroups - eligibleGroups.length) / amountGroups

  const onChangeAmount = (amount) => {
    setAmountGroups(amount)
  }

  const onChangeType = (type) => {
    setGroupType(type)
  }

  const onChangeDepth = (depth) => {
    setGroupDepth(depth)
  }

  return (
    <Box flex={1}>
      <Box ref={containerRef}>
        <Header
          amount={amountGroups}
          groupDepth={groupDepth}
          groupType={groupType}
          onChangeAmount={onChangeAmount}
          onChangeDepth={onChangeDepth}
          onChangeType={onChangeType}
        />

        <Box
          paddingTop={24}
          flexDirection="row"
          justifyContent="center"
          alignSelf="center">
          <Box alignItems="center">
            <StatisticCard
              statistic={{
                title: 'Groups left',
                value:
                  isFetchingGroups && !isSuccess ? '-' : eligibleGroups.length,
              }}
              chart={
                <RingProgress
                  height={80}
                  width={80}
                  autoFit={false}
                  percent={percent}
                  color={percent === 1 ? '#72c240' : ['#5B8FF9', '#E8EDF3']}
                  innerRadius={0.7}
                  statistic={
                    percent === 1
                      ? {
                          content: {
                            content: 'Done',
                            style: { fontWeight: '500', color: '#72c240' },
                          },
                        }
                      : false
                  }
                />
              }
              chartPlacement="left"
            />
          </Box>

          <Divider
            type="vertical"
            style={{ height: '100%', marginRight: 48, marginLeft: 48 }}
          />

          <Box transparent>
            <InputNumber
              addonBefore="Top"
              addonAfter="Groups"
              size="small"
              min={1}
              max={1000}
              defaultValue={amountGroups}
              onChange={onChangeAmount}
              style={{ marginBottom: 12, width: 180 }}
            />

            <TypeSelect
              onChange={onChangeType}
              type={groupType}
              style={{ width: '100%', marginBottom: 12 }}
            />

            <DepthSelect
              onChange={onChangeDepth}
              depth={groupDepth}
              style={{ width: '100%' }}
            />

            <Button
              type="primary"
              disabled={eligibleGroups.length === 0 && isSuccess}
              loading={isFetchingGroups}
              onClick={() =>
                show({
                  amountGroups,
                  groupType,
                  groupDepth,
                })
              }
              style={styleSheets.margin.top[24]}>
              Show Modal
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
