import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LineChartOutlined,
} from '@ant-design/icons'
import { Line } from '@ant-design/plots'
import { useDebounceCallback } from '@react-hook/debounce'
import {
  groupModes,
  headerModes,
} from '@tellonym/core/artificialTells/constants'
import * as colorSystem from '@tellonym/core/common/colorSystem'
import { normalize } from '@tellonym/core/helpers'
import {
  ARTIFICIAL_TELL_GROUP_TYPES,
  ARTIFICIAL_TELL_QUESTION_DEPTH,
  ARTIFICIAL_TELL_SENDER_HINT_TYPE,
  ARTIFICIAL_TELL_STATUS,
  ARTIFICIAL_TELL_TARGET_GENDER,
} from '@tellonym/enums/lib/Tell'
import {
  Button,
  Grid,
  Input,
  InputNumber,
  Modal,
  Select,
  Skeleton,
  Spin,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import * as ReactRouter from 'react-router'
import { getPermissions } from '../../app/selectors'
import {
  Box,
  BoxSecondary,
  Col,
  colors,
  FlatList,
  helpers,
  hooks,
  Row,
  styleSheets,
  theme,
} from '../../common'
import { TagBox } from '../../common/components/TagBox'
import { updateLocationSearch } from '../../common/helpers'
import {
  createVariance,
  editGroup,
  editVariance,
  refreshGroup,
  refreshVarianceAnalytics,
  setShouldShowDeletedVariances,
} from '../actionsV2'
import {
  useActivateAllVariancesMutation,
  useDeleteAllVariancesInAllLanguagesMutation,
  useDeleteAllVariancesMutation,
  useGroupStatsGraphQuery,
} from '../queries'
import {
  getDataMode,
  getGroupMode,
  getHeaderMode,
  getIsRefreshingGroupDetails,
  getIsRefreshingVarianceAnalytics,
  getLanguage,
  getShouldShowDeletedVariances,
} from '../selectorsV2'
import { ArtificialTellAnswers } from './ArtificialTellAnswers'
import { ArtificialTellItemV2 } from './ArtificialTellItemV2'
import { BreadcrumbHeader } from './BreadcrumbHeader'
import { ButtonModalCreateTodo } from './ButtonModalCreateTodo'
import { GptAnalysisBox } from './GptAnalysisBox'
import { MedianDataForDataMode } from './MedianDataForDataMode'
import { ModalArtificialTellAnswers } from './ModalArtificialTellAnswers'
import { ModalMoveGroupToOtherTopic } from './ModalMoveGroupToOtherTopic'
import { ModalNotLocalizedSpeedTranslation } from './ModalNotLocalizedSpeedTranslation'
import { ModalVarianceGenerator } from './ModalVarianceGenerator'
import { TodoItem } from './PageArtificialTellsTodos'
import { PerformanceCheckIns } from './PerformanceCheckIns'

const styles = {
  clickable: { cursor: 'pointer' },
}

const TAB_HEIGHT = 263

const useEditGroup = (group) => {
  const dispatch = ReactRedux.useDispatch()

  return React.useCallback(
    (payload) => {
      dispatch(
        editGroup({
          id: group.id,
          ...payload,
        })
      )
    },
    [dispatch, group]
  )
}

const ShTypeSelect = ({ onChange, senderHintType, isDisabled }) => {
  return (
    <Tooltip title="Sender Hint Type">
      <Select
        disabled={isDisabled}
        size="small"
        options={[
          {
            label: (
              <TagBox
                backgroundColor={
                  colors.artTellSenderHint[
                    ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE_DEFAULT
                  ]
                }
                text="Not set"
                isLabel
              />
            ),
            value: ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE_DEFAULT,
          },
          {
            label: (
              <TagBox
                backgroundColor={
                  colors.artTellSenderHint[
                    ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE
                  ]
                }
                text="None"
                isLabel
              />
            ),
            value: ARTIFICIAL_TELL_SENDER_HINT_TYPE.NONE,
          },
          {
            label: (
              <TagBox
                backgroundColor={
                  colors.artTellSenderHint[
                    ARTIFICIAL_TELL_SENDER_HINT_TYPE.PRETEND_ORGANIC
                  ]
                }
                text="Pretend Organic"
                isLabel
              />
            ),
            value: ARTIFICIAL_TELL_SENDER_HINT_TYPE.PRETEND_ORGANIC,
          },
          {
            label: (
              <TagBox
                backgroundColor={
                  colors.artTellSenderHint[
                    ARTIFICIAL_TELL_SENDER_HINT_TYPE.REVEAL
                  ]
                }
                text="Reveal"
                isLabel
              />
            ),
            value: ARTIFICIAL_TELL_SENDER_HINT_TYPE.REVEAL,
          },
        ]}
        onChange={onChange}
        defaultValue={senderHintType}
        style={{ width: 140, marginRight: 12 }}
      />
    </Tooltip>
  )
}

export const TypeSelect = ({ type, onChange, isDisabled, style }) => (
  <Tooltip title="Group Type">
    <Select
      disabled={isDisabled}
      size="small"
      options={[
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC]
              }
              text="Organic"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_GROUP_TYPES.ORGANIC,
        },
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE]
              }
              text="Simple"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_GROUP_TYPES.SIMPLE,
        },
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.OPEN]
              }
              text="Open"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_GROUP_TYPES.OPEN,
        },
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.SHARE]
              }
              text="Share"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_GROUP_TYPES.SHARE,
        },
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellGroup[ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT]
              }
              text="Statement"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_GROUP_TYPES.STATEMENT,
        },
      ]}
      onChange={onChange}
      defaultValue={type}
      style={{ width: 120, marginRight: 12, ...style }}
    />
  </Tooltip>
)

export const DepthSelect = ({ onChange, depth, isDisabled, style }) => (
  <Tooltip title="Question Depth">
    <Select
      disabled={isDisabled}
      size="small"
      options={[
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellDepth[ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO]
              }
              text="Intro"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_QUESTION_DEPTH.INTRO,
        },
        {
          label: (
            <TagBox
              backgroundColor={
                colors.artTellDepth[ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP]
              }
              text="Deep"
              isLabel
            />
          ),
          value: ARTIFICIAL_TELL_QUESTION_DEPTH.DEEP,
        },
      ]}
      onChange={onChange}
      defaultValue={depth}
      style={{ width: 100, marginRight: 12, ...style }}
    />
  </Tooltip>
)

export const TextAreaWithLabel = ({
  label,
  value,
  onChange,
  isDisabled,
  maxLength,
}) => {
  return (
    <Box flex={1} transparent marginBottom={8}>
      <Typography.Text style={{ fontSize: 10, fontWeight: '700' }}>
        {label}
      </Typography.Text>

      <Input.TextArea
        disabled={isDisabled}
        value={value}
        onChange={onChange}
        autoSize={{ minRows: 1, maxRows: 3 }}
        maxLength={maxLength}
        style={{ width: '100%' }}
      />
    </Box>
  )
}

const NameSection = ({ group, isDisabled }) => {
  const [groupName, setGroupName] = React.useState(group.name || '')

  const dispatch = ReactRedux.useDispatch()

  const editGroup = useEditGroup(group)

  const saveGroupName = useDebounceCallback(
    React.useCallback(
      (groupName) => {
        dispatch(
          editGroup({
            id: group.id,
            name: groupName,
          })
        )
      },
      [dispatch, group.id]
    ),
    1500,
    false
  )

  const onChangeGroupName = (e) => {
    setGroupName(e.target.value)
    saveGroupName(e.target.value)
  }

  React.useEffect(() => {
    if (groupName === '' && group.name) {
      setGroupName(group.name)
    }
  }, [groupName, group.name])

  return (
    <TextAreaWithLabel
      isDisabled={isDisabled}
      label="Group Name"
      value={groupName}
      onChange={onChangeGroupName}
    />
  )
}

const ChangeGroupPropertiesSection = ({ group, isDisabled }) => {
  const isRepeated = group.reaskInDays > 0

  const editGroup = useEditGroup(group)

  const toggleIsRepeated = () => {
    if (group.reaskInDays > 0) {
      editGroup({ reaskInDays: 0 })
    } else {
      editGroup({ reaskInDays: 1 })
    }
  }

  const onChangeReaskInDays = (reaskInDays) => {
    if (reaskInDays !== group.reaskInDays) {
      editGroup({ reaskInDays })
    }
  }

  return (
    <Row wrap marginTop={24} justify="space-between" gutter={[0, 12]}>
      <Box transparent flexDirection="row">
        <ShTypeSelect
          isDisabled={isDisabled}
          senderHintType={group.senderHintType}
          onChange={(type) => {
            editGroup({ senderHintType: type })
          }}
        />
        <TypeSelect
          isDisabled={isDisabled}
          onChange={(type) => {
            editGroup({ type })
          }}
          type={group.type}
        />
        <DepthSelect
          isDisabled={isDisabled}
          onChange={(depth) => {
            editGroup({ depth })
          }}
          depth={group.depth}
        />
      </Box>

      {isRepeated ? (
        <InputNumber
          disabled={Boolean(group.reaskInDays) === false || isDisabled}
          min={0}
          max={365}
          value={group.reaskInDays ?? 0}
          onChange={onChangeReaskInDays}
          addonBefore={
            <Tooltip
              placement="topLeft"
              title="Click 'Reuse after' to disable repeated">
              <Typography.Text
                onClick={toggleIsRepeated}
                style={{ cursor: 'pointer' }}>
                Reuse after
              </Typography.Text>
            </Tooltip>
          }
          addonAfter="days"
          size="small"
          style={{ maxWidth: 200 }}
        />
      ) : (
        <Button disabled={isDisabled} size="small" onClick={toggleIsRepeated}>
          Set repeated
        </Button>
      )}
    </Row>
  )
}

const DescriptionSection = ({ group, isDisabled }) => {
  const dispatch = ReactRedux.useDispatch()

  const [description, setDescription] = React.useState(group.description || '')

  const saveDescription = useDebounceCallback(
    React.useCallback(
      (description) => {
        dispatch(
          editGroup({
            id: group.id,
            description,
          })
        )
      },
      [dispatch, group]
    ),
    1500,
    false
  )

  React.useEffect(() => {
    if (description === '' && group.description) {
      setDescription(group.description)
    }
  }, [description, group.description])

  const onChangeDescription = (e) => {
    setDescription(e.target.value)
    saveDescription(e.target.value)
  }

  return (
    <TextAreaWithLabel
      isDisabled={isDisabled}
      label="Description"
      value={description}
      onChange={onChangeDescription}
    />
  )
}

const ActionsSection = ({ group }) => {
  const dispatch = ReactRedux.useDispatch()
  const match = ReactRouter.useRouteMatch()

  const {
    mutate: fetchDeleteAllVariances,
    isLoading: isLoadingDeleteAllVariances,
  } = useDeleteAllVariancesMutation({
    groupId: group?.id,
    language: parseInt(match.params.groupId, 10),
    ids: group?.variances.map((variance) => variance.id),
  })

  const {
    mutate: fetchDeleteAllVariancesInAllLanguages,
    isLoading: isLoadingDeleteAllVariancesInAllLanguages,
  } = useDeleteAllVariancesInAllLanguagesMutation({
    groupId: group?.id,
    ids: group?.variances.map((variance) => variance.id),
  })

  const onPressDeleteAllVariances = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete all variances?',
      onOk: () => {
        fetchDeleteAllVariances()
      },
    })
  }

  const { mutate: fetchActivateAllVariances, isLoading: isLoadingActivate } =
    useActivateAllVariancesMutation({
      groupId: group?.id,
      language: parseInt(match.params.groupId, 10),
      ids: group?.variances
        .filter(
          (variance) => variance.status === ARTIFICIAL_TELL_STATUS.INACTIVE
        )
        .map((variance) => variance.id),
    })

  const onPressActivateAllVariances = () => {
    Modal.confirm({
      title: 'Are you sure you want to activate all variances?',
      onOk: () => {
        fetchActivateAllVariances()
      },
    })
  }

  const onPressDeleteAllVariancesInAllLanguages = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete all variances in all languages?',
      onOk: () => {
        fetchDeleteAllVariancesInAllLanguages()
      },
    })
  }

  const firstInactiveVariance = group?.variances?.find(
    (variance) => variance.status === ARTIFICIAL_TELL_STATUS.INACTIVE
  )

  const setValidationQaStatus = () => {
    if (!firstInactiveVariance) {
      return
    }

    dispatch(
      editVariance({
        id: firstInactiveVariance.id,
        groupId: parseInt(match.params.groupId, 10),
        status: ARTIFICIAL_TELL_STATUS.VALIDATION,
      })
    )
  }

  const moveGroupToOtherTopic = () => {
    ModalMoveGroupToOtherTopic.show({
      topicId: group?.topic?.id,
      group,
    })
  }

  return (
    <Box
      flexDirection="row"
      marginTop={24}
      transparent
      alignItems="flex-start"
      gap={12}>
      <Box flexDirection="row" gap={24} transparent>
        <Button
          disabled={!firstInactiveVariance}
          onClick={setValidationQaStatus}>
          Validate Group
        </Button>

        <Button onClick={moveGroupToOtherTopic}>Move Group</Button>

        <Button
          loading={isLoadingActivate}
          onClick={onPressActivateAllVariances}>
          Activate inactive variances
        </Button>
      </Box>

      <Box flexDirection="row" transparent>
        <Button
          type="link"
          danger
          loading={isLoadingDeleteAllVariances}
          onClick={onPressDeleteAllVariances}>
          Delete all variances
        </Button>

        <Button
          type="link"
          danger
          loading={isLoadingDeleteAllVariancesInAllLanguages}
          onClick={onPressDeleteAllVariancesInAllLanguages}>
          Delete all vars in all languages
        </Button>
      </Box>
    </Box>
  )
}

const ColTodo = ({ group }) => {
  const language = ReactRedux.useSelector(getLanguage)
  const permissions = ReactRedux.useSelector(getPermissions)
  const isTranslator = helpers.checkIsTranslator(permissions)

  return (
    <Col xs={24} lg={12} xl={6} xxl={4}>
      <Box transparent alignItems="flex-end" justifyContent="space-between">
        <TodoItem item={group.todo} width="100%" isDisabled={isTranslator} />

        <ButtonModalCreateTodo
          group={group}
          language={language}
          size="small"
          style={styleSheets.margin.top[12]}
          disabled={isTranslator}
        />
      </Box>
    </Col>
  )
}

const HeaderEditTab = ({ group }) => {
  return (
    <Row justify="space-between" gutter={[12, 24]}>
      <Col xs={24} xl={12} xxl={10}>
        <NameSection group={group} />
        <DescriptionSection group={group} />
        <TextAreaWithLabel
          isDisabled
          label="Topic description"
          value={group.topic.description}
        />
        <ChangeGroupPropertiesSection group={group} />

        <ActionsSection group={group} />
      </Col>

      <ColTodo group={group} />
    </Row>
  )
}

const Graph = ({ group }) => {
  const dataMode = ReactRedux.useSelector(getDataMode)
  const language = ReactRedux.useSelector(getLanguage)
  const [lastXDays, setLastXDays] = React.useState(30)

  const { data, isLoading } = useGroupStatsGraphQuery({
    groupId: group?.id,
    language,
    dataMode,
    lastXDays,
  })

  const dataSource = React.useMemo(() => {
    if (!data) {
      return []
    }

    const result = data.reduce((acc, item) => {
      const { date, ...restKeys } = item

      Object.keys(restKeys).forEach((key) => {
        const value = item[key]

        if (value === null) {
          return
        }

        acc.push({
          date,
          value,
          dataType: key,
        })
      })

      return acc
    }, [])

    return result.reverse()
  }, [data])

  const legendKeys = Object.keys(data?.[0] ?? {}).filter(
    (key) => key !== 'date'
  )

  const lineConfig = {
    xField: 'date',
    yField: 'value',
    seriesField: 'dataType',
    padding: 'auto',
    connectNulls: false,
    xAxis: {
      label: {
        formatter: (v) => dayjs(v).format('DD.MM.YY'),
      },
    },
    legend: {
      layout: 'horizontal',
      position: 'right',
      flipPage: false,
      selected: {
        ...legendKeys.reduce((acc, key) => ({ ...acc, [key]: false }), {}),
        answerRate: true,
        shareRate: true,
      },
    },
    tooltip: {
      position: 'right',
      offset: 120,
    },
    interactions: [{ type: 'legendFilter' }, { type: 'elementSelect' }],
  }

  const onPressQuickTimeCreator = (time) => () => {
    setLastXDays(time)
  }

  return (
    <Box transparent>
      <Box transparent marginBottom={24}>
        <Spin spinning={isLoading}>
          <Line data={dataSource} {...lineConfig} />
        </Spin>
      </Box>

      <Row align="end" gutter={[12, 12]}>
        <Col>
          <Tag
            color="blue"
            onClick={onPressQuickTimeCreator(7)}
            style={styles.clickable}>
            Last 7d
          </Tag>
          <Tag
            color="blue"
            onClick={onPressQuickTimeCreator(30)}
            style={styles.clickable}>
            Last 14d
          </Tag>
          <Tag
            color="blue"
            onClick={onPressQuickTimeCreator(30)}
            style={styles.clickable}>
            Last 30d
          </Tag>
          <Tag
            color="blue"
            onClick={onPressQuickTimeCreator(90)}
            style={styles.clickable}>
            Last 90d
          </Tag>
        </Col>
        <Col>
          <InputNumber
            size="small"
            addonBefore="Last"
            addonAfter="days"
            value={lastXDays}
            onChange={setLastXDays}
            style={{ width: 160 }}
          />
        </Col>
      </Row>
    </Box>
  )
}

const GroupStats = ({ group }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false)

  const screens = Grid.useBreakpoint()

  const dispatch = ReactRedux.useDispatch()

  const getThemedColor = hooks.useThemedColor()

  const isRefreshingGroupDetails = ReactRedux.useSelector(
    getIsRefreshingGroupDetails
  )

  const onChangeDataMode = () => {
    dispatch(refreshGroup({ groupId: group.id }))
  }

  return (
    <>
      <Modal
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={screens.lg ? 900 : '100%'}>
        <Graph group={group} />
      </Modal>
      <MedianDataForDataMode
        data={[
          {
            texts: [
              'Total Sent:',
              'Answer Rate:',
              'P50 Length:',
              'P80 Length:',
              'Share Rate:',
            ],
            values: [
              group.analytics.totalSent,
              group.analytics.answerRate,
              group.analytics.p50Length,
              group.analytics.p80Length,
              group.analytics.shareRate,
            ],
          },
        ]}
        extra={
          <Button
            onClick={() => setIsModalVisible(true)}
            icon={<LineChartOutlined />}
            size="small"
            style={styleSheets.margin.left[12]}
          />
        }
        isLoading={isRefreshingGroupDetails}
        onChange={onChangeDataMode}
        backgroundColor={getThemedColor(
          theme.colors.antdBackgroundElevated,
          colorSystem.colors.black[5]
        )}
        tableWidth={220}
      />
    </>
  )
}

const HeaderStatsTab = ({ group }) => {
  const language = ReactRedux.useSelector(getLanguage)

  return (
    <Row justify="space-between" gutter={[12, 24]}>
      <Col xs={24} lg={12} xl={6}>
        <Col xl={24} xxl={{ span: 12, offset: 6 }}>
          <GroupStats group={group} />
        </Col>
      </Col>

      <Col xs={24} lg={12} xl={6}>
        <GptAnalysisBox group={group} language={language} />
      </Col>

      <Col xs={24} lg={12} xl={6}>
        <PerformanceCheckIns groupId={group.id} language={language} />
      </Col>

      <ColTodo group={group} />
    </Row>
  )
}

const HeaderTranslateTab = ({ group }) => {
  const language = ReactRedux.useSelector(getLanguage)

  return (
    <Row justify="space-between" gutter={[12, 24]}>
      <Col xs={24} xl={10} xxl={10}>
        <NameSection group={group} isDisabled />
        <DescriptionSection group={group} isDisabled />
        <TextAreaWithLabel
          isDisabled
          label="Topic description"
          value={group.topic.description}
        />
        <ChangeGroupPropertiesSection group={group} isDisabled />

        <Button
          type="primary"
          onClick={() =>
            ModalNotLocalizedSpeedTranslation.show({
              language,
              groupId: group.id,
            })
          }
          style={styleSheets.margin.top[12]}>
          Start Speed-Translation
        </Button>
      </Col>

      <Col xs={24} lg={12} xl={8}>
        <PerformanceCheckIns groupId={group.id} language={language} />
      </Col>

      <ColTodo group={group} />
    </Row>
  )
}

const GroupHeader = ({ group }) => {
  const dispatch = ReactRedux.useDispatch()

  const screens = Grid.useBreakpoint()

  const groupMode = ReactRedux.useSelector(getGroupMode)
  const headerMode = ReactRedux.useSelector(getHeaderMode)
  const language = ReactRedux.useSelector(getLanguage)

  const hasNoVariances = group?.variances?.length === 0

  const shouldShowDeletedVariances = ReactRedux.useSelector(
    getShouldShowDeletedVariances
  )

  const SelectedTab = React.useMemo(() => {
    switch (headerMode) {
      case headerModes.EDIT:
        return HeaderEditTab
      case headerModes.STATS:
        return HeaderStatsTab
      case headerModes.TRANSLATE:
        return HeaderTranslateTab
      default: {
        switch (groupMode) {
          case groupModes.EDIT:
            return HeaderEditTab
          case groupModes.STATS:
            return HeaderStatsTab
          default:
            return () => null
        }
      }
    }
  }, [groupMode, headerMode])

  const inputRef = React.useRef(null)

  const [content, setContent] = React.useState('')

  const onSubmitNewEntry = () => {
    if (content !== '') {
      dispatch(
        createVariance({
          groupId: group.id,
          content,
          gender: ARTIFICIAL_TELL_TARGET_GENDER.BOTH,
          status: ARTIFICIAL_TELL_STATUS.INACTIVE,
        })
      )

      setContent('')
    }
  }

  const onPressShowAnswers = () => {
    ModalArtificialTellAnswers.show({ groupId: group.id })
  }

  const onPressShowDeletedVariances = () => {
    dispatch(setShouldShowDeletedVariances(!shouldShowDeletedVariances))
  }

  const onPressGenerateVariances = () => {
    ModalVarianceGenerator.show({ groupId: group.id, language })
  }

  hooks.useEscapeKey({
    inputRef,
    onPress: () => {
      setContent('')
    },
  })

  return (
    <>
      <BoxSecondary
        paddingHorizontal={24}
        paddingVertical={12}
        hasBottomBorder
        borderBottomWidth={0.5}>
        <BreadcrumbHeader group={group} language={language} />

        <Box transparent minHeight={TAB_HEIGHT}>
          <SelectedTab group={group} />
        </Box>
      </BoxSecondary>

      <Box paddingHorizontal={24} paddingTop={12}>
        <Row gutter={[12, 12]}>
          <Col xs={24} xl={13}>
            <Input.Group compact>
              <Input
                ref={inputRef}
                style={{ width: 'calc(100% - 120px)' }}
                defaultValue={content}
                onChange={(e) => setContent(e.target.value)}
                onPressEnter={onSubmitNewEntry}
                value={content}
                placeholder="New Entry..."
              />
              <Tooltip title="Pressing ENTER also saves it">
                <Button
                  type="bordered"
                  onClick={onSubmitNewEntry}
                  style={{ width: 120 }}>
                  Create Entry
                </Button>
              </Tooltip>
            </Input.Group>
          </Col>
          <Col xs={24} xl={11}>
            <Row
              justify={{ xs: 'start', md: 'space-between' }}
              gutter={[0, 12]}>
              <Tooltip
                title={
                  hasNoVariances
                    ? 'Variance generation needs at least one variance to work.'
                    : undefined
                }>
                <Button
                  disabled={hasNoVariances}
                  type="primary"
                  onClick={onPressGenerateVariances}
                  style={styleSheets.margin.right[12]}>
                  Generate variances
                </Button>
              </Tooltip>
              {screens.md && <Box flex={1} />}
              <Button
                icon={
                  shouldShowDeletedVariances ? (
                    <EyeInvisibleOutlined />
                  ) : (
                    <EyeOutlined />
                  )
                }
                onClick={onPressShowDeletedVariances}
                style={styleSheets.margin.right[12]}>
                {shouldShowDeletedVariances ? 'Hide deleted' : 'Show deleted'}
              </Button>
              <Button onClick={onPressShowAnswers}>See all answers</Button>
            </Row>
          </Col>
        </Row>
      </Box>
    </>
  )
}

const Item = (props) => {
  const getThemedColor = hooks.useThemedColor()

  return (
    <Box
      paddingTop={8}
      paddingBottom={8}
      borderTopWidth={0}
      borderLeftWidth={0}
      borderRightWidth={0}
      borderBottomWidth={1}
      borderColor={
        props.hasSeparator
          ? getThemedColor(
              theme.colors.antdBackgroundElevatedBorder,
              colorSystem.colors.black[4]
            )
          : 'transparent'
      }
      borderStyle="solid"
      transparent>
      <Row gutter={8}>
        <Col span={16}>
          <ArtificialTellItemV2 {...props} />
        </Col>
        <Col span={8} />
      </Row>
    </Box>
  )
}

export const PageArtificialTellsGroupDetailsV2 = ({ match }) => {
  const dispatch = ReactRedux.useDispatch()

  const getThemedColor = hooks.useThemedColor()

  const groupMode = ReactRedux.useSelector(getGroupMode)
  const headerMode = ReactRedux.useSelector(getHeaderMode)

  const [areStatsVisible, setAreStatsVisible] = React.useState(false)

  const isRefreshingVarianceAnalytics = ReactRedux.useSelector(
    getIsRefreshingVarianceAnalytics
  )
  const shouldShowDeletedVariances = ReactRedux.useSelector(
    getShouldShowDeletedVariances
  )

  const groupId = parseInt(match.params.groupId, 10)

  const dataMode = ReactRedux.useSelector(getDataMode)
  const language = ReactRedux.useSelector(getLanguage)

  const { group, items } = ReactRedux.useSelector((state) => {
    const group = state.artificialTellsV2.groupDetails[groupId]

    return {
      group,
      items: normalize(group?.variances ?? []),
    }
  })

  const actions = React.useMemo(
    () => ({
      refresh: (payload) =>
        dispatch(
          refreshGroup({
            groupId,
            showDeletedVariances: shouldShowDeletedVariances,
            ...payload,
          })
        ),
    }),
    [dispatch, groupId, shouldShowDeletedVariances]
  )

  const extraProps = React.useMemo(
    () => ({
      areStatsVisible,
      setAreStatsVisible,
      groupStats: {
        answerRate: group?.analytics?.answerRate?.amount,
        totalSent: group?.analytics?.totalSent?.amount,
        dataMode: group?.analytics?.dataMode,
      },
      groupStatus: group?.status,
      isRefreshingVarianceAnalytics,
      isStatsDisabled: headerMode === headerModes.TRANSLATE,
      isStatusDisabled: headerMode === headerModes.TRANSLATE,
    }),
    [
      areStatsVisible,
      group?.analytics,
      group?.status,
      isRefreshingVarianceAnalytics,
      headerMode,
    ]
  )

  const extraData = React.useMemo(
    () => ({
      areStatsVisible,
      hasMore: false,
      isFetching: false,
      isRefreshing: false,
      group,
    }),
    [group, areStatsVisible]
  )

  React.useEffect(() => {
    updateLocationSearch({ groupMode })
  }, [groupMode])

  React.useEffect(() => {
    updateLocationSearch({ headerMode })
  }, [headerMode])

  React.useEffect(() => {
    if (typeof groupId !== 'undefined' && typeof language !== 'undefined') {
      dispatch(
        refreshGroup(
          { groupId, showDeletedVariances: shouldShowDeletedVariances },
          { shouldDiscardVariances: true }
        )
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [groupId, language, shouldShowDeletedVariances])

  React.useEffect(() => {
    if (areStatsVisible) {
      dispatch(
        refreshVarianceAnalytics({
          varianceIds: items.ids,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areStatsVisible, dataMode])

  React.useEffect(() => {
    if (areStatsVisible) {
      setAreStatsVisible(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [language])

  if (!group?.id) {
    return null
  }

  return (
    <Box flex={1}>
      <GroupHeader group={group} />

      <Box flexDirection="row">
        <FlatList
          actions={actions}
          component={Item}
          extraData={extraData}
          extraProps={extraProps}
          items={items}
          isRefreshing={false}
          locale={{
            emptyText: items ? null : <Skeleton active={true} />,
          }}
        />

        <Box
          marginTop={8}
          marginRight={4}
          width={350}
          height={504}
          borderWidth={1}
          borderColor={getThemedColor(
            theme.colors.antdBackgroundElevatedBorder,
            colorSystem.colors.black[4]
          )}
          borderStyle="solid">
          <ArtificialTellAnswers groupId={groupId} height={500} />
        </Box>
      </Box>
    </Box>
  )
}
