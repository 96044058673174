import { DownOutlined, SettingOutlined, UpOutlined } from '@ant-design/icons'
import { intHash } from '@tellonym/core/helpers'
import { Divider, Tag, Typography } from 'antd'
import React from 'react'
import { Box } from '../../common'
import { antdTagColors } from '../../common/styles/colors'
import { ModalTopicTags } from './ModalTopicTags'

export const TopicTags = ({ tags, onPressTag, activeTags, hasEditTag }) => {
  const [isExpanded, setIsExpanded] = React.useState(false)
  const [height, setHeight] = React.useState('60px')
  const boxRef = React.useRef(null)

  React.useEffect(() => {
    if (boxRef.current) {
      const newHeight = isExpanded ? `${boxRef.current.scrollHeight}px` : '60px'
      setHeight(newHeight)
    }
  }, [isExpanded, tags])

  const onPressAddTag = () => {
    ModalTopicTags.show()
  }

  if (!tags) {
    return null
  }

  return (
    <Box transparent>
      <Box
        ref={boxRef}
        transparent
        flexDirection="row"
        flexWrap="wrap"
        overflow="hidden"
        maxHeight={height}
        style={{ transition: 'max-height 0.3s ease-out' }}>
        {hasEditTag && (
          <Typography.Text
            type="link"
            onClick={onPressAddTag}
            style={{ marginBottom: 8, marginRight: 12, cursor: 'pointer' }}>
            <SettingOutlined />
          </Typography.Text>
        )}

        {tags.map((tag) => (
          <Tag
            key={tag}
            color={
              activeTags?.includes(tag)
                ? antdTagColors[intHash(tag) % antdTagColors.length]
                : undefined
            }
            onClick={() => onPressTag?.(tag)}
            style={{
              marginRight: 8,
              marginBottom: 8,
              cursor: typeof onPressTag === 'function' ? 'pointer' : 'default',
            }}>
            {tag}
          </Tag>
        ))}
      </Box>

      <Divider
        plain
        orientation="right"
        onClick={() => setIsExpanded(!isExpanded)}
        style={{
          cursor: 'pointer',
          marginTop: 0,
          marginBottom: 0,
          fontSize: 12,
        }}>
        {isExpanded ? <UpOutlined /> : <DownOutlined />} Show More
      </Divider>
    </Box>
  )
}
