export const ACTIVATE_COMMUNITY_BELL = 'communities/ACTIVATE_COMMUNITY_BELL'
export const ACTIVATE_COMMUNITY_BELL_SUCCESS = 'communities/ACTIVATE_COMMUNITY_BELL_SUCCESS' // prettier-ignore
export const ACTIVATE_COMMUNITY_BELL_ERROR = 'communities/ACTIVATE_COMMUNITY_BELL_ERROR' // prettier-ignore

export const DEACTIVATE_COMMUNITY_BELL = 'communities/DEACTIVATE_COMMUNITY_BELL'
export const DEACTIVATE_COMMUNITY_BELL_SUCCESS = 'communities/DEACTIVATE_COMMUNITY_BELL_SUCCESS' // prettier-ignore
export const DEACTIVATE_COMMUNITY_BELL_ERROR = 'communities/DEACTIVATE_COMMUNITY_BELL_ERROR' // prettier-ignore

export const BLOCK_FROM_COMMUNITY = 'communities/BLOCK_FROM_COMMUNITY'
export const BLOCK_FROM_COMMUNITY_ERROR = 'communities/BLOCK_FROM_COMMUNITY_ERROR' // prettier-ignore
export const BLOCK_FROM_COMMUNITY_SUCCESS = 'communities/BLOCK_FROM_COMMUNITY_SUCCESS' // prettier-ignore

export const UNBLOCK_FROM_COMMUNITY = 'communities/UNBLOCK_FROM_COMMUNITY' // prettier-ignore
export const UNBLOCK_FROM_COMMUNITY_ERROR = 'communities/UNBLOCK_FROM_COMMUNITY_ERROR' // prettier-ignore
export const UNBLOCK_FROM_COMMUNITY_SUCCESS = 'communities/UNBLOCK_FROM_COMMUNITY_SUCCESS' // prettier-ignore

export const CHANGE_COMMUNITY_SETTINGS = 'communities/CHANGE_COMMUNITY_SETTINGS'
export const CHANGE_COMMUNITY_SETTINGS_ERROR = 'communities/CHANGE_COMMUNITY_SETTINGS_ERROR' // prettier-ignore
export const CHANGE_COMMUNITY_SETTINGS_SUCCESS = 'communities/CHANGE_COMMUNITY_SETTINGS_SUCCESS' // prettier-ignore

export const CHECK_COMMUNITY_NAME_AVAILABILITY = 'communities/CHECK_COMMUNITY_NAME_AVAILABILITY' // prettier-ignore
export const CHECK_COMMUNITY_NAME_AVAILABILITY_ERROR = 'communities/CHECK_COMMUNITY_NAME_AVAILABILITY_ERROR' // prettier-ignore
export const CHECK_COMMUNITY_NAME_AVAILABILITY_SUCCESS = 'communities/CHECK_COMMUNITY_NAME_AVAILABILITY_SUCCESS' // prettier-ignore

export const CREATE_COMMUNITY = 'communities/CREATE_COMMUNITY'
export const CREATE_COMMUNITY_ERROR = 'communities/CREATE_COMMUNITY_ERROR' // prettier-ignore
export const CREATE_COMMUNITY_SUCCESS = 'communities/CREATE_COMMUNITY_SUCCESS' // prettier-ignore

export const FETCH_COMMUNITY_BLOCKED_USERS = 'communities/FETCH_COMMUNITY_BLOCKED_USERS' // prettier-ignore
export const FETCH_COMMUNITY_BLOCKED_USERS_SUCCESS = 'communities/FETCH_COMMUNITY_BLOCKED_USERS_SUCCESS' // prettier-ignore
export const FETCH_COMMUNITY_BLOCKED_USERS_ERROR = 'communities/FETCH_COMMUNITY_BLOCKED_USERS_ERROR' // prettier-ignore

export const FETCH_COMMUNITIES = 'communities/FETCH_COMMUNITIES'
export const FETCH_COMMUNITIES_ERROR = 'communities/FETCH_COMMUNITIES_ERROR'
export const FETCH_COMMUNITIES_SUCCESS = 'communities/FETCH_COMMUNITIES_SUCCESS'

export const FETCH_COMMUNITY_FOLLOWINGS = 'communities/FETCH_COMMUNITY_FOLLOWINGS' // prettier-ignore
export const FETCH_COMMUNITY_FOLLOWINGS_SUCCESS = 'communities/FETCH_COMMUNITY_FOLLOWINGS_SUCCESS' // prettier-ignore
export const FETCH_COMMUNITY_FOLLOWINGS_ERROR = 'communities/FETCH_COMMUNITY_FOLLOWINGS_ERROR' // prettier-ignore

export const FETCH_COMMUNITY_POSTS = 'communities/FETCH_COMMUNITY_POSTS'
export const FETCH_COMMUNITY_POSTS_SUCCESS = 'communities/FETCH_COMMUNITY_POSTS_SUCCESS' // prettier-ignore
export const FETCH_COMMUNITY_POSTS_ERROR = 'communities/FETCH_COMMUNITY_POSTS_ERROR' // prettier-ignore

export const FETCH_COMMUNITY_SETTINGS = 'communities/FETCH_COMMUNITY_SETTINGS'
export const FETCH_COMMUNITY_SETTINGS_ERROR = 'communities/FETCH_COMMUNITY_SETTINGS_ERROR' // prettier-ignore
export const FETCH_COMMUNITY_SETTINGS_SUCCESS = 'communities/FETCH_COMMUNITY_SETTINGS_SUCCESS' // prettier-ignore

export const FETCH_COMMUNITY_TELLS = 'communities/FETCH_COMMUNITY_TELLS'
export const FETCH_COMMUNITY_TELLS_SUCCESS = 'communities/FETCH_COMMUNITY_TELLS_SUCCESS' // prettier-ignore
export const FETCH_COMMUNITY_TELLS_ERROR = 'communities/FETCH_COMMUNITY_TELLS_ERROR' // prettier-ignore

export const HIDE_COMMUNITY = 'communities/HIDE_COMMUNITY'
export const HIDE_COMMUNITY_ERROR = 'communities/HIDE_COMMUNITY_ERROR'
export const HIDE_COMMUNITY_SUCCESS = 'communities/HIDE_COMMUNITY_SUCCESS'

export const HIDE_COMMUNITY_POST = 'communities/HIDE_COMMUNITY_POST'
export const HIDE_COMMUNITY_POST_ERROR = 'communities/HIDE_COMMUNITY_POST_ERROR'
export const HIDE_COMMUNITY_POST_SUCCESS = 'communities/HIDE_COMMUNITY_POST_SUCCESS' // prettier-ignore

export const REFRESH_COMMUNITY_BLOCKED_USERS = 'communities/REFRESH_COMMUNITY_BLOCKED_USERS' // prettier-ignore
export const REFRESH_COMMUNITY_BLOCKED_USERS_SUCCESS = 'communities/REFRESH_COMMUNITY_BLOCKED_USERS_SUCCESS' // prettier-ignore
export const REFRESH_COMMUNITY_BLOCKED_USERS_ERROR = 'communities/REFRESH_COMMUNITY_BLOCKED_USERS_ERROR' // prettier-ignore

export const REFRESH_COMMUNITY_TELLS = 'communities/REFRESH_COMMUNITY_TELLS'
export const REFRESH_COMMUNITY_TELLS_SUCCESS = 'communities/REFRESH_COMMUNITY_TELLS_SUCCESS' // prettier-ignore
export const REFRESH_COMMUNITY_TELLS_ERROR = 'communities/REFRESH_COMMUNITY_TELLS_ERROR' // prettier-ignore

export const REFRESH_COMMUNITIES = 'communities/REFRESH_COMMUNITIES'
export const REFRESH_COMMUNITIES_ERROR = 'communities/REFRESH_COMMUNITIES_ERROR'
export const REFRESH_COMMUNITIES_SUCCESS = 'communities/REFRESH_COMMUNITIES_SUCCESS' // prettier-ignore

export const REFRESH_COMMUNITY_FOLLOWINGS = 'communities/REFRESH_COMMUNITY_FOLLOWINGS' // prettier-ignore
export const REFRESH_COMMUNITY_FOLLOWINGS_SUCCESS = 'communities/REFRESH_COMMUNITY_FOLLOWINGS_SUCCESS' // prettier-ignore
export const REFRESH_COMMUNITY_FOLLOWINGS_ERROR = 'communities/REFRESH_COMMUNITY_FOLLOWINGS_ERROR' // prettier-ignore

export const REFRESH_COMMUNITY_POSTS = 'communities/REFRESH_COMMUNITY_POSTS'
export const REFRESH_COMMUNITY_POSTS_SUCCESS = 'communities/REFRESH_COMMUNITY_POSTS_SUCCESS' // prettier-ignore
export const REFRESH_COMMUNITY_POSTS_ERROR = 'communities/REFRESH_COMMUNITY_POSTS_ERROR' // prettier-ignore

export const FOLLOW_COMMUNITY = 'communities/FOLLOW_COMMUNITY'
export const FOLLOW_COMMUNITY_ERROR = 'communities/FOLLOW_COMMUNITY_ERROR'
export const FOLLOW_COMMUNITY_SUCCESS = 'communities/FOLLOW_COMMUNITY_SUCCESS'

export const UNFOLLOW_COMMUNITY = 'communities/UNFOLLOW_COMMUNITY'
export const UNFOLLOW_COMMUNITY_ERROR = 'communities/UNFOLLOW_COMMUNITY_ERROR'
export const UNFOLLOW_COMMUNITY_SUCCESS = 'communities/UNFOLLOW_COMMUNITY_SUCCESS' // prettier-ignore

export const REMOVE_COMMUNITY_TELL = 'communities/REMOVE_COMMUNITY_TELL'
export const REMOVE_COMMUNITY_TELL_ERROR = 'communities/REMOVE_COMMUNITY_TELL_ERROR' // prettier-ignore
export const REMOVE_COMMUNITY_TELL_SUCCESS = 'communities/REMOVE_COMMUNITY_TELL_SUCCESS' // prettier-ignore

export const REQUEST_COMMUNITY_CREATION_REQUIREMENTS = 'communities/REQUEST_COMMUNITY_CREATION_REQUIREMENTS' // prettier-ignore
export const REQUEST_COMMUNITY_CREATION_REQUIREMENTS_ERROR = 'communities/REQUEST_COMMUNITY_CREATION_REQUIREMENTS_ERROR' // prettier-ignore
export const REQUEST_COMMUNITY_CREATION_REQUIREMENTS_SUCCESS = 'communities/REQUEST_COMMUNITY_CREATION_REQUIREMENTS_SUCCESS' // prettier-ignore

export const WRITE_COMMUNITY_TELL = 'communities/WRITE_COMMUNITY_TELL'
export const WRITE_COMMUNITY_TELL_SUCCESS = 'communities/WRITE_COMMUNITY_TELL_SUCCESS' // prettier-ignore
export const WRITE_COMMUNITY_TELL_ERROR = 'communities/WRITE_COMMUNITY_TELL_ERROR' // prettier-ignore

export const LIKE_COMMUNITY_POST = 'communities/LIKE_COMMUNITY_POST'
export const LIKE_COMMUNITY_POST_ERROR = 'communities/LIKE_COMMUNITY_POST_ERROR'
export const LIKE_COMMUNITY_POST_SUCCESS = 'communities/LIKE_COMMUNITY_POST_SUCCESS' // prettier-ignore

export const UNLIKE_COMMUNITY_POST = 'communities/UNLIKE_COMMUNITY_POST'
export const UNLIKE_COMMUNITY_POST_ERROR = 'communities/UNLIKE_COMMUNITY_POST_ERROR' // prettier-ignore
export const UNLIKE_COMMUNITY_POST_SUCCESS = 'communities/UNLIKE_COMMUNITY_POST_SUCCESS' // prettier-ignore

export const FETCH_COMMUNITY_MEMBERS = 'communities/FETCH_COMMUNITY_MEMBERS'
export const FETCH_COMMUNITY_MEMBERS_SUCCESS = 'communities/FETCH_COMMUNITY_MEMBERS_SUCCESS' // prettier-ignore
export const FETCH_COMMUNITY_MEMBERS_ERROR = 'communities/FETCH_COMMUNITY_MEMBERS_ERROR' // prettier-ignore

export const REFRESH_COMMUNITY_MEMBERS = 'communities/REFRESH_COMMUNITY_MEMBERS'
export const REFRESH_COMMUNITY_MEMBERS_SUCCESS = 'communities/REFRESH_COMMUNITY_MEMBERS_SUCCESS' // prettier-ignore
export const REFRESH_COMMUNITY_MEMBERS_ERROR = 'communities/REFRESH_COMMUNITY_MEMBERS_ERROR' // prettier-ignore

export const REFRESH_COMMUNITY_TELL_SUBMISSIONS = 'communities/REFRESH_COMMUNITY_TELL_SUBMISSIONS' // prettier-ignore
export const REFRESH_COMMUNITY_TELL_SUBMISSIONS_SUCCESS = 'communities/REFRESH_COMMUNITY_TELL_SUBMISSIONS_SUCCESS' // prettier-ignore
export const REFRESH_COMMUNITY_TELL_SUBMISSIONS_ERROR = 'communities/REFRESH_COMMUNITY_TELL_SUBMISSIONS_ERROR' // prettier-ignore

export const FETCH_COMMUNITY_TELL_SUBMISSIONS = 'communities/FETCH_COMMUNITY_TELL_SUBMISSIONS' // prettier-ignore
export const FETCH_COMMUNITY_TELL_SUBMISSIONS_SUCCESS = 'communities/FETCH_COMMUNITY_TELL_SUBMISSIONS_SUCCESS' // prettier-ignore
export const FETCH_COMMUNITY_TELL_SUBMISSIONS_ERROR = 'communities/FETCH_COMMUNITY_TELL_SUBMISSIONS_ERROR' // prettier-ignore

export const WRITE_COMMUNITY_TELL_SUBMISSION = 'communities/WRITE_COMMUNITY_TELL_SUBMISSION' // prettier-ignore
export const WRITE_COMMUNITY_TELL_SUBMISSION_SUCCESS = 'communities/WRITE_COMMUNITY_TELL_SUBMISSION_SUCCESS' // prettier-ignore
export const WRITE_COMMUNITY_TELL_SUBMISSION_ERROR = 'communities/WRITE_COMMUNITY_TELL_SUBMISSION_ERROR' // prettier-ignore

export const REMOVE_COMMUNITY_TELL_SUBMISSION = 'communities/REMOVE_COMMUNITY_TELL_SUBMISSION' // prettier-ignore
export const REMOVE_COMMUNITY_TELL_SUBMISSION_SUCCESS = 'communities/REMOVE_COMMUNITY_TELL_SUBMISSION_SUCCESS' // prettier-ignore
export const REMOVE_COMMUNITY_TELL_SUBMISSION_ERROR = 'communities/REMOVE_COMMUNITY_TELL_SUBMISSION_ERROR' // prettier-ignore

export const REMOVE_COMMUNITY = 'communities/REMOVE_COMMUNITY'
export const REMOVE_COMMUNITY_SUCCESS = 'communities/REMOVE_COMMUNITY_SUCCESS'
export const REMOVE_COMMUNITY_ERROR = 'communities/REMOVE_COMMUNITY_ERROR'

export const REPORT_COMMUNITY_TELL_SUBMISSION = 'communities/REPORT_COMMUNITY_TELL_SUBMISSION' // prettier-ignore
export const REPORT_COMMUNITY_TELL_SUBMISSION_SUCCESS = 'communities/REPORT_COMMUNITY_TELL_SUBMISSION_SUCCESS' // prettier-ignore
export const REPORT_COMMUNITY_TELL_SUBMISSION_ERROR = 'communities/REPORT_COMMUNITY_TELL_SUBMISSION_ERROR' // prettier-ignore

export const REMOVE_COMMUNITY_TELL_SENDER_STATUS = 'communities/REMOVE_COMMUNITY_TELL_SENDER_STATUS' // prettier-ignore
export const REMOVE_COMMUNITY_TELL_SENDER_STATUS_SUCCESS = 'communities/REMOVE_COMMUNITY_TELL_SENDER_STATUS_SUCCESS' // prettier-ignore
export const REMOVE_COMMUNITY_TELL_SENDER_STATUS_ERROR = 'communities/REMOVE_COMMUNITY_TELL_SENDER_STATUS_ERROR' // prettier-ignore

export const RESET_DRAFT_COMMUNITY = 'communities/RESET_DRAFT_COMMUNITY' // prettier-ignore

export const SET_HAS_DISMISSED_ADMIN_INBOX_HELPER = 'communities/SET_HAS_DISMISSED_ADMIN_INBOX_HELPER' // prettier-ignore

export const UPDATE_DRAFT_COMMUNITY = 'communities/UPDATE_DRAFT_COMMUNITY' // prettier-ignore

export const SET_CONTENT_FILTER = 'communities/SET_CONTENT_FILTER'

export const REFRESH_COMMUNITY = 'communities/REFRESH_COMMUNITY'
export const REFRESH_COMMUNITY_SUCCESS = 'communities/REFRESH_COMMUNITY_SUCCESS'
export const REFRESH_COMMUNITY_ERROR = 'communities/REFRESH_COMMUNITY_ERROR'

export const REFRESH_COMMUNITY_ITEMS = 'communities/REFRESH_COMMUNITY_ITEMS' // prettier-ignore
export const REFRESH_COMMUNITY_ITEMS_SUCCESS = 'communities/REFRESH_COMMUNITY_ITEMS_SUCCESS' // prettier-ignore
export const REFRESH_COMMUNITY_ITEMS_ERROR = 'communities/REFRESH_COMMUNITY_ITEMS_ERROR' // prettier-ignore

export const FETCH_COMMUNITY_ITEMS = 'communities/FETCH_COMMUNITY_ITEMS' // prettier-ignore
export const FETCH_COMMUNITY_ITEMS_SUCCESS = 'communities/FETCH_COMMUNITY_ITEMS_SUCCESS' // prettier-ignore
export const FETCH_COMMUNITY_ITEMS_ERROR = 'communities/FETCH_COMMUNITY_ITEMS_ERROR' // prettier-ignore

export const REFRESH_COMMUNITY_MEMBER_SEARCH = 'communities/REFRESH_COMMUNITY_MEMBER_SEARCH' // prettier-ignore
export const REFRESH_COMMUNITY_MEMBER_SEARCH_SUCCESS = 'communities/REFRESH_COMMUNITY_MEMBER_SEARCH_SUCCESS' // prettier-ignore
export const REFRESH_COMMUNITY_MEMBER_SEARCH_ERROR = 'communities/REFRESH_COMMUNITY_MEMBER_SEARCH_ERROR' // prettier-ignore

export const FETCH_COMMUNITY_MEMBER_SEARCH = 'communities/FETCH_COMMUNITY_MEMBER_SEARCH' // prettier-ignore
export const FETCH_COMMUNITY_MEMBER_SEARCH_SUCCESS = 'communities/FETCH_COMMUNITY_MEMBER_SEARCH_SUCCESS' // prettier-ignore
export const FETCH_COMMUNITY_MEMBER_SEARCH_ERROR = 'communities/FETCH_COMMUNITY_MEMBER_SEARCH_ERROR' // prettier-ignore
