import { PERFORMANCE_TYPE } from '@tellonym/enums/lib/TellArtificial'
import { Select, Typography } from 'antd'
import React from 'react'
import { Box, FlatList, styleSheets } from '../../common'
import { languageEnumAsString } from '../../common/helpers'
import { useActivityQuery } from '../queries'
import { ListItem } from './PerformanceCheckIns'

const performanceTypeOptions = [{ label: 'All', value: 'all' }].concat(
  [
    PERFORMANCE_TYPE.COMMENT,
    PERFORMANCE_TYPE.MONITOR,
    PERFORMANCE_TYPE.OPPORTUNITY,
    PERFORMANCE_TYPE.CHANGELOG_GROUP_ACTIVATE,
    PERFORMANCE_TYPE.CHANGELOG_GROUP_CREATE,
    PERFORMANCE_TYPE.CHANGELOG_GROUP_DEACTIVATE,
    PERFORMANCE_TYPE.CHANGELOG_GROUP_DEPTH,
    PERFORMANCE_TYPE.CHANGELOG_GROUP_DESCRIPTION,
    PERFORMANCE_TYPE.CHANGELOG_GROUP_NAME,
    PERFORMANCE_TYPE.CHANGELOG_GROUP_SENDER_HINT_TYPE,
    PERFORMANCE_TYPE.CHANGELOG_GROUP_TOPIC_CHANGE,
    PERFORMANCE_TYPE.CHANGELOG_GROUP_TYPE,
    PERFORMANCE_TYPE.CHANGELOG_REASKABILITY,
    PERFORMANCE_TYPE.CHANGELOG_START_VALIDATION,
    PERFORMANCE_TYPE.CHANGELOG_VARIANCE_ACTIVATE,
    PERFORMANCE_TYPE.CHANGELOG_VARIANCE_CHANGE,
    PERFORMANCE_TYPE.CHANGELOG_VARIANCE_CREATE,
    PERFORMANCE_TYPE.CHANGELOG_VARIANCE_DEACTIVATE,
    PERFORMANCE_TYPE.CHANGELOG_VARIANCE_GENDER,
  ].map((value) => ({
    value,
    label: PERFORMANCE_TYPE[value].replace('CHANGELOG_', ''),
  }))
)

const ActivityItem = ({ item }) => {
  const isGroup = typeof item.groupId === 'number'

  const langString = languageEnumAsString[item?.language]

  const onPress = (e) => {
    e.preventDefault()

    const isActionKeyPressed = e.metaKey || e.ctrlKey || e.button === 1

    const route = isGroup
      ? `/artificialtells_v2/${langString}/group/${item.groupId}`
      : `/artificialtells_v2/${langString}/topic/${item.topicId}`

    /**
     * Window open is essential to update the language selection.
     */
    if (isActionKeyPressed) {
      window.open(route, '_blank')
    } else {
      window.open(route, '_self')
    }
  }

  return (
    <Box flex={1} width={900} onMouseDown={onPress} cursor="pointer">
      <ListItem isActivity item={item} />
    </Box>
  )
}

const Header = ({
  onChangeLanguage,
  language,
  languageOptions,
  onChangeCompletedByFilter,
  performanceType,
  performanceTypeOptions,
  onChangePerformanceType,
  completedByFilter,
  userOptions,
}) => {
  return (
    <Box
      flexDirection="row"
      alignItems="center"
      style={{ marginTop: 24, marginBottom: 24 }}>
      <Box flexDirection="row" alignItems="center">
        <Typography.Text style={{ paddingRight: 5 }}>Type:</Typography.Text>
        <Select
          options={performanceTypeOptions}
          onChange={onChangePerformanceType}
          value={performanceType}
          style={{ width: 220 }}
        />
      </Box>

      {languageOptions?.length > 2 && (
        <Box
          flexDirection="row"
          alignItems="center"
          style={styleSheets.margin.left[24]}>
          <Typography.Text style={{ paddingRight: 5 }}>
            Language:
          </Typography.Text>
          <Select
            options={languageOptions}
            onChange={onChangeLanguage}
            value={language}
          />
        </Box>
      )}

      <Box
        flexDirection="row"
        alignItems="center"
        style={styleSheets.margin.left[24]}>
        <Typography.Text style={{ paddingRight: 5 }}>
          Completed By:
        </Typography.Text>
        <Select
          options={userOptions}
          onChange={onChangeCompletedByFilter}
          value={completedByFilter}
          style={{ width: 120 }}
        />
      </Box>
    </Box>
  )
}

export const PageArtificialTellsActivity = () => {
  const [language, setLanguage] = React.useState('all')
  const [completedByFilter, setCompletedByFilter] = React.useState('all')
  const [performanceType, setPerformanceType] = React.useState('all')

  const [languageOptions, setLanguageOptions] = React.useState([
    { label: 'All', value: 'all' },
  ])

  const [userOptions, setUserOptions] = React.useState([
    { label: 'All', value: 'all' },
  ])

  const {
    data,
    isLoading,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useActivityQuery({
    language: language === 'all' ? undefined : language,
    performanceType: performanceType === 'all' ? undefined : performanceType,
    userId: completedByFilter === 'all' ? undefined : completedByFilter,
    limit: 100,
  })

  const actions = React.useMemo(
    () => ({
      fetch: (payload) => {
        fetchNextPage({
          pageParam: payload,
          cancelRefetch: false,
        })
      },
      refresh: (payload) => {
        refetch(payload)
      },
    }),
    [fetchNextPage, refetch]
  )

  const extraData = React.useMemo(
    () => ({
      hasMore: hasNextPage,
      isFetching: isFetchingNextPage,
      isRefreshing: isLoading,
    }),
    [hasNextPage, isFetchingNextPage, isLoading]
  )

  React.useEffect(() => {
    if (typeof data?.possibleParams?.userId !== 'undefined') {
      const options = data?.possibleParams.userId.reduce(
        (acc, user) => {
          const { username, id } = user

          return [...acc, { label: username, value: id }]
        },
        [{ label: 'All', value: 'all' }]
      )

      setUserOptions(options)
    }
  }, [data?.possibleParams?.userId])

  React.useEffect(() => {
    if (typeof data?.possibleParams?.language !== 'undefined') {
      const langs = data?.possibleParams.language.reduce(
        (acc, curr) => {
          return [
            ...acc,
            {
              label: languageEnumAsString[curr],
              value: curr,
            },
          ]
        },
        [{ label: 'All', value: 'all' }]
      )

      setLanguageOptions(langs)
    }
  }, [data?.possibleParams?.language])

  const onChangeCompletedByFilter = React.useCallback(
    (value) => {
      setCompletedByFilter(value)
    },
    [setCompletedByFilter]
  )

  const onChangeLanguage = React.useCallback(
    (value) => {
      setLanguage(value)
    },
    [setLanguage]
  )

  const onChangePerformanceType = React.useCallback(
    (value) => {
      setPerformanceType(value)
    },
    [setPerformanceType]
  )

  if (!data) {
    return null
  }

  return (
    <FlatList
      items={data.items}
      component={ActivityItem}
      actions={actions}
      extraData={extraData}
      hasMore={extraData.hasMore}
      isFetching={extraData.isFetching}
      isRefreshing={extraData.isRefreshing}
      contentContainerStyle={{ alignItems: 'center' }}
      renderHeader={() => (
        <Header
          completedByFilter={completedByFilter}
          onChangeLanguage={onChangeLanguage}
          onChangeCompletedByFilter={onChangeCompletedByFilter}
          language={language}
          languageOptions={languageOptions}
          userOptions={userOptions}
          performanceType={performanceType}
          performanceTypeOptions={performanceTypeOptions}
          onChangePerformanceType={onChangePerformanceType}
        />
      )}
    />
  )
}
