import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import { buildFetch, resolveOrReject } from '@tellonym/core/api'
import { getAccessToken } from '@tellonym/core/app/selectors'
import { normalize } from '@tellonym/core/helpers'
import { langDetectObjectsByType1 } from '@tellonym/enums/lib/Language'
import { ARTIFICIAL_TELL_STATUS } from '@tellonym/enums/lib/Tell'
import { message } from 'antd'
import { mergeDeepRight } from 'ramda'
import React from 'react'
import * as ReactRedux from 'react-redux'
import { useLocation } from 'react-router-dom'
import { _ } from '../common'
import { buildRequest, errorHandler, queryClient } from '../common/queries'
import { store } from '../common/store'
import { refreshGroup, refreshTopic } from './actionsV2'
import { getDataMode, getLanguage } from './selectorsV2'

export const artellv2 = {
  dashboard: (payload) => ['dashboard', payload],
  workProgress: (payload) => ['dashboard', 'workProgress', payload],
  groups: ['groups'],
  activateAllVariances: (payload) => [
    ...artellv2.groups,
    'activateAllVariances',
    payload,
  ],
  addbulk: () => [...artellv2.groups, 'addbulk'],
  addPerformanceCheckIn: (payload) => [
    ...artellv2.groups,
    'addPerformanceCheckIn',
    payload,
  ],
  allGroups: (payload) => [...artellv2.groups, 'all', payload],
  answers: (payload) => [...artellv2.groups, 'answers', payload],
  deleteAllVariances: (payload) => [
    ...artellv2.groups,
    'deleteAllVarianes',
    payload,
  ],
  deleteAllVariancesInAllLanguages: (payload) => [
    ...artellv2.groups,
    'deleteAllVariancesInAllLanguages',
    payload,
  ],
  deleteInactiveGroups: (payload) => [
    ...artellv2.groups,
    'deleteInactiveGroups',
    payload,
  ],
  gptGroupAnalysisStart: (payload) => [
    ...artellv2.groups,
    'gptAnalysisStart',
    payload,
  ],
  gptGroupAnalysisResult: (payload) => [
    ...artellv2.groups,
    'gptAnalysisResult',
    payload,
  ],
  groupStatsGraph: (payload) => [
    ...artellv2.groups,
    'groupStatsGraph',
    payload,
  ],
  outperformingGroups: (payload) => [
    ...artellv2.groups,
    'outperformingGroups',
    payload,
  ],
  notLocalizedGroups: (payload) => [
    ...artellv2.groups,
    'notLocalizedGroups',
    payload,
  ],
  performanceCheckIns: (payload) => [
    ...artellv2.groups,
    'performanceCheckIns',
    payload,
  ],
  activity: (payload) => [...artellv2.groups, 'activity', payload],
  speedTranslations: (payload) => [
    ...artellv2.groups,
    'speedTranslations',
    payload,
  ],
  speedTranslationsRegenerate: () => [
    ...artellv2.groups,
    'speedTranslationsRegenerate',
  ],
  todos: (payload) => [...artellv2.groups, 'todos', payload],
  activateInactiveGroups: (payload) => [
    ...artellv2.groups,
    'activateInactiveGroups',
    payload,
  ],
  tags: ['tags'],
  tagsRemove: (payload) => [...artellv2.tags, 'remove', payload],
  tagsAdd: (payload) => [...artellv2.tags, 'add', payload],
  topics: ['topics'],
  allTopics: (payload) => [...artellv2.topics, 'all', payload],
  validation: ['validation'],
  validateInactiveGroups: (payload) => [
    ...artellv2.groups,
    'validateInactiveGroups',
    payload,
  ],
  varianceGeneration: (payload) => [
    ...artellv2.groups,
    'varianceGeneration',
    payload,
  ],
  varianceGenerationRegenerate: () => [
    ...artellv2.groups,
    'varianceGenerationRegenerate',
  ],
  profileSharingDashboard: (payload) => [
    'profileSharing',
    'dashboard',
    payload,
  ],
}

const useGroupOrTopicRefresh = () => {
  const dispatch = ReactRedux.useDispatch()
  const location = useLocation()

  const handleGroupOrTopicRefresh = React.useCallback(() => {
    const isGroupDetailsPage = location.pathname.includes('group/')
    const isTopicDetailsPage = location.pathname.includes('topic/')

    if (isGroupDetailsPage) {
      const [, , , , groupId] = location.pathname.split('/')

      dispatch(refreshGroup({ groupId }))
    } else if (isTopicDetailsPage) {
      const [, , , , topicId] = location.pathname.split('/')

      dispatch(refreshTopic({ topicId }))
    }
  }, [dispatch, location.pathname])

  return handleGroupOrTopicRefresh
}

const fetchAllGroups = async ({ queryKey }) => {
  const { language, dataMode } = queryKey[2]

  const lang = langDetectObjectsByType1[language]

  const accessToken = getAccessToken(store.getState())

  const [url, fetchConfig] = buildFetch(
    {
      path: `admin/artificialtells/v2/groups?lang=${lang}`,
      method: 'GET',
      accessToken,
      payload: { dataMode },
    },
    { accessToken }
  )

  const response = await fetch(url, fetchConfig)

  return resolveOrReject(response, null, false)
}

export const useAllGroupsQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.allGroups(payload),
    queryFn: fetchAllGroups,
    staleTime: 15 * _.min,
    refetchOnWindowFocus: false,
  })

  return result
}

/**
 * There is only groupId or topicId in the payload
 */
const fetchGptGroupAnalysisStart = ({ groupId, topicId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/answers/chatgpt',
    method: 'POST',
    buildPayload: () => ({
      topicId,
      groupId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useGptGroupAnalysisStartMutation = (payload) => {
  const result = useMutation({
    mutationKey: artellv2.gptGroupAnalysisStart(payload),
    mutationFn: fetchGptGroupAnalysisStart(payload),
    onSuccess: payload.onSuccess,
  })

  return result
}

/**
 * There is only groupId or topicId in the payload
 */
const fetchGptGroupAnalysisResult = ({ groupId, topicId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/answers/chatgpt',
    buildPayload: () => ({
      groupId,
      topicId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useGptGroupAnalysisResult = (payload) => {
  const result = useQuery({
    queryKey: artellv2.gptGroupAnalysisResult(payload),
    queryFn: fetchGptGroupAnalysisResult(payload),
    staleTime: 1 * _.h,
  })

  return result
}

const fetchDeleteAllVariances =
  ({ groupId, language, ids }) =>
  () =>
    Promise.all(
      ids.map((id) =>
        buildRequest({
          path: 'admin/artificialtells/v2/variance/update',
          method: 'POST',
          buildPayload: () => ({
            groupId,
            id,
            language: langDetectObjectsByType1[language],
            status: ARTIFICIAL_TELL_STATUS.DELETED,
          }),
        })()
      )
    )

const fetchActivateAllVariances =
  ({ groupId, language, ids }) =>
  () =>
    Promise.all(
      ids.map((id) =>
        buildRequest({
          path: 'admin/artificialtells/v2/variance/update',
          method: 'POST',
          buildPayload: () => ({
            groupId,
            id,
            language: langDetectObjectsByType1[language],
            status: ARTIFICIAL_TELL_STATUS.ACTIVE,
          }),
        })()
      )
    )

const fetchDeleteAllVariancesInAllLanguages = ({ groupId }) =>
  buildRequest({
    path: '/admin/artificialtells/v2/variances/update',
    method: 'POST',
    buildPayload: () => ({
      groupId,
      status: ARTIFICIAL_TELL_STATUS.DELETED,
    }),
  })

export const useDeleteAllVariancesMutation = (payload) => {
  const dispatch = ReactRedux.useDispatch()

  const result = useMutation({
    enabled: payload.group?.id,
    mutationKey: artellv2.deleteAllVariances(payload),
    mutationFn: fetchDeleteAllVariances(payload),
    onSuccess: () => {
      dispatch(refreshGroup({ groupId: payload.groupId }))
      message.success('Variances deleted')
    },
  })

  return result
}

export const useActivateAllVariancesMutation = (payload) => {
  const dispatch = ReactRedux.useDispatch()

  const result = useMutation({
    enabled: payload.group?.id,
    mutationKey: artellv2.activateAllVariances(payload),
    mutationFn: fetchActivateAllVariances(payload),
    onSuccess: () => {
      dispatch(refreshGroup({ groupId: payload.groupId }))
      message.success('Variances activated')
    },
  })

  return result
}

export const useDeleteAllVariancesInAllLanguagesMutation = (payload) => {
  const dispatch = ReactRedux.useDispatch()

  const result = useMutation({
    enabled: payload.group?.id,
    mutationKey: artellv2.deleteAllVariancesInAllLanguages(payload),
    mutationFn: fetchDeleteAllVariancesInAllLanguages(payload),
    onSuccess: () => {
      dispatch(refreshGroup({ groupId: payload.groupId }))
      message.success('Variances in all languages deleted')
    },
  })

  return result
}

const fetchAnswers = ({ language, dataMode }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/answers',
    method: 'GET',
    buildPayload: ({ queryKey, pageParam = {} }) => ({
      ...queryKey[2],
      ...pageParam,
      limit: 48,
      dataMode,
      language,
    }),
  })

export const useAnswersQuery = (payload) => {
  const languageString = ReactRedux.useSelector(getLanguage)
  const dataMode = ReactRedux.useSelector(getDataMode)

  const mergedPayload = {
    ...payload,
    language: langDetectObjectsByType1[languageString],
    dataMode,
  }

  const result = useInfiniteQuery({
    queryKey: artellv2.answers(mergedPayload),
    queryFn: fetchAnswers(mergedPayload),
    getNextPageParam: () => true,
  })

  const mergedResult = React.useMemo(() => {
    const answers = result.data?.pages?.flatMap((page) => page.answers) ?? []

    const { group, hasMore } = result.data?.pages?.[0] ?? {}

    const normalisedAnswers = normalize(answers)

    const uniqueAnswerIds = [...new Set(normalisedAnswers.ids)]

    return {
      ...result,
      data: {
        group,
        answers: { data: normalisedAnswers.data, ids: uniqueAnswerIds },
        hasMore,
      },
    }
  }, [result])

  return mergedResult
}

const fetchPerformanceCheckIns = ({
  groupId,
  topicId,
  language,
  performanceType,
  limit,
  userId,
}) =>
  buildRequest({
    path: 'admin/artificialtells/v2/performance',
    method: 'GET',
    buildPayload: ({ queryKey, pageParam = {} }) => ({
      ...queryKey[2],
      ...pageParam,
      groupId,
      topicId,
      language:
        typeof language === 'number'
          ? language
          : langDetectObjectsByType1[language],
      performanceType,
      limit,
      userId,
    }),
  })

export const usePerformanceCheckInsQuery = ({
  enabled = false,
  ...payload
}) => {
  const result = useQuery({
    queryKey: artellv2.performanceCheckIns(payload),
    queryFn: fetchPerformanceCheckIns(payload),
    staleTime: 1 * _.h,
    refetchOnWindowFocus: false,
    enabled: enabled || !!payload.groupId || !!payload.topicId,
  })

  return result
}

export const useActivityQuery = (payload) => {
  const result = useInfiniteQuery({
    queryKey: artellv2.activity(payload),
    queryFn: fetchPerformanceCheckIns(payload),
    getNextPageParam: (lastPage, pages) => {
      if (lastPage.meta.hasMore) {
        return pages.length + 1
      }

      return undefined
    },
  })

  const mergedResult = React.useMemo(() => {
    if (!result.data?.pages) {
      return {
        ...result,
        items: { ids: [], data: {} },
        possibleParams: {},
      }
    }

    const items = result.data.pages.reduce(
      (acc, page) => {
        acc.ids = Array.from(new Set([...acc.ids, ...page.items.ids]))

        acc.data = {
          ...acc.data,
          ...page.items.data,
        }

        return acc
      },
      { ids: [], data: {} }
    ) ?? { ids: [], data: {} }

    return {
      ...result,
      data: {
        items,
        possibleParams: result.data?.pages?.[0]?.possibleParams ?? {},
      },
    }
  }, [result])

  return mergedResult
}

const fetchAddPerformanceCheckIn = buildRequest({
  path: 'admin/artificialtells/v2/performance/create',
  method: 'POST',
  buildPayload: (payload) => ({
    ...payload,
    language: langDetectObjectsByType1[payload.language],
  }),
})

export const useAddPerformanceCheckInMutation = ({
  groupId,
  topicId,
  language,
}) => {
  const queryClient = useQueryClient()

  const result = useMutation({
    mutationKey: artellv2.addPerformanceCheckIn({ groupId, topicId }),
    mutationFn: fetchAddPerformanceCheckIn,
    onSuccess: () => {
      // invalidate performance check ins
      queryClient.invalidateQueries({
        queryKey: artellv2.performanceCheckIns({ groupId, topicId, language }),
      })
    },
  })

  return result
}

const fetchGroupStatsGraph = ({ groupId, language }) =>
  buildRequest({
    path: `admin/artificialtells/v2/group/graph?lang=${langDetectObjectsByType1[language]}&id=${groupId}`,
    method: 'GET',
    buildPayload: ({ queryKey }) => ({
      dataMode: queryKey[2].dataMode,
      lastXDays: queryKey[2].lastXDays,
    }),
  })

export const useGroupStatsGraphQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.groupStatsGraph(payload),
    queryFn: fetchGroupStatsGraph(payload),
    staleTime: 1 * _.h,
  })

  return result
}

const fetchNotLocalizedGroups = ({ language, dataMode }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/groups/not-localized',
    method: 'GET',
    buildPayload: () => ({
      lang: langDetectObjectsByType1[language],
      dataMode,
    }),
  })

export const useNotLocalizedGroupsQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.notLocalizedGroups(payload),
    queryFn: fetchNotLocalizedGroups(payload),
    staleTime: 15 * _.min,
    refetchOnWindowFocus: false,
  })

  return result
}

const fetchOutperformingGroups = ({ language, dataMode }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/groups/outperforming',
    buildPayload: () => ({
      language: langDetectObjectsByType1[language],
      dataMode,
    }),
  })

export const useOutperformingGroupsQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.outperformingGroups(payload),
    queryFn: fetchOutperformingGroups(payload),
  })

  return result
}

const fetchTodos = () =>
  buildRequest({
    path: 'admin/artificialtells/v2/todo',
    buildPayload: ({ queryKey, pageParam = {} }) => ({
      ...queryKey[2],
      ...pageParam,
    }),
  })

export const useTodosQuery = (payload) => {
  const result = useInfiniteQuery({
    queryKey: artellv2.todos(payload),
    queryFn: fetchTodos(payload),
    getNextPageParam: () => true,
  })

  const mergedResult = React.useMemo(() => {
    const todos = result.data?.pages?.flatMap((page) => page.todos) ?? []

    const normalised = normalize(todos)

    return {
      ...result,
      data: normalised,
      possibleParams: result.data?.pages?.[0]?.possibleParams ?? {},
    }
  }, [result])

  return mergedResult
}

const fetchDeleteInactiveGroups = ({ topicId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/update-all?action=delete',
    method: 'POST',
    buildPayload: () => ({
      topicId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useDeleteInactiveGroupsMutation = (payload) => {
  const dispatch = ReactRedux.useDispatch()

  const result = useMutation({
    mutationKey: artellv2.deleteInactiveGroups(payload),
    mutationFn: fetchDeleteInactiveGroups(payload),
    onSuccess: () => {
      dispatch(refreshTopic({ topicId: payload.topicId }))
      message.success('Inactive groups deleted')
    },
  })

  return result
}

const fetchActivateInactiveGroups = ({ topicId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/update-all?action=activate',
    method: 'POST',
    buildPayload: () => ({
      topicId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useActivateInactiveGroupsMutation = (payload) => {
  const dispatch = ReactRedux.useDispatch()

  const result = useMutation({
    mutationKey: artellv2.activateInactiveGroups(payload),
    mutationFn: fetchActivateInactiveGroups(payload),
    onSuccess: () => {
      dispatch(refreshTopic({ topicId: payload.topicId }))
    },
  })

  return result
}

const fetchValidateInactiveGroups = ({ topicId, language }) =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/update-all?action=validate',
    method: 'POST',
    buildPayload: () => ({
      topicId,
      language: langDetectObjectsByType1[language],
    }),
  })

export const useValidateInactiveGroupsMutation = (payload) => {
  const dispatch = ReactRedux.useDispatch()

  const result = useMutation({
    mutationKey: artellv2.validateInactiveGroups(payload),
    mutationFn: fetchValidateInactiveGroups(payload),
    onSuccess: () => {
      dispatch(refreshTopic({ topicId: payload.topicId }))
    },
  })

  return result
}

const fetchAddBulk = () =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/create-bulk',
    method: 'POST',
    buildPayload: ({ language, ...payload }) => ({
      language: langDetectObjectsByType1[language],
      ...payload,
    }),
  })

export const useAddBulkMutation = () => {
  const result = useMutation({
    mutationKey: artellv2.addbulk(),
    mutationFn: fetchAddBulk(),
  })

  return result
}

const fetchValidation = () =>
  buildRequest({
    path: 'admin/artificialtells/validation',
    method: 'GET',
  })

export const useValidationQuery = () => {
  const result = useQuery({
    queryKey: artellv2.validation,
    queryFn: fetchValidation(),
    refetchOnWindowFocus: false,
  })

  return result
}

export const fetchDashboard = () =>
  buildRequest({
    path: 'admin/artificialtells/dashboard/artells',
    method: 'GET',
    buildPayload: ({ queryKey }) => ({
      language: langDetectObjectsByType1[queryKey[1].language],
    }),
  })

export const useDashboardQuery = (props) => {
  const language = ReactRedux.useSelector(getLanguage)

  const { keepPreviousData, ...payload } = props ?? {}

  const result = useQuery({
    queryKey: artellv2.dashboard({ ...payload, language }),
    queryFn: fetchDashboard(),
    staleTime: 1 * _.h,
    refetchOnWindowFocus: false,
    retry: 3,
    onError: errorHandler,
    keepPreviousData,
  })

  return { ...result, language }
}

export const fetchVarianceGeneration = () =>
  buildRequest({
    path: 'admin/artificialtells/v2/variances/generate',
    method: 'POST',
    buildPayload: ({ queryKey }) => {
      return {
        language: langDetectObjectsByType1[queryKey[2].language],
        groupId: queryKey[2].groupId,
      }
    },
  })

export const useVarianceGenerationQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.varianceGeneration(payload),
    queryFn: fetchVarianceGeneration(payload),
    refetchOnWindowFocus: false,
    staleTime: 15 * _.min,
  })

  return result
}

const fetchVarianceGenerationRegenerate = buildRequest({
  path: 'admin/artificialtells/v2/variances/generate',
  method: 'POST',
  buildPayload: ({ language, groupId, prevResponseQuestions }) => {
    return {
      language: langDetectObjectsByType1[language],
      groupId,
      prevResponseQuestions,
    }
  },
})

/**
 * This function is only for having the regeneration possibility of a single gender
 * for the variance generation. It fetches a whole new result but only updates the
 * query cache for the specific gender.
 * Needs to receive the same language and groupId in mutate as for useVarianceGenerationQuery
 * to update the correct query cache.
 */
export const useVarianceGenerationRegenerateMutation = () => {
  const queryClient = useQueryClient()

  const result = useMutation({
    mutationKey: artellv2.varianceGenerationRegenerate(),
    mutationFn: fetchVarianceGenerationRegenerate,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        artellv2.varianceGeneration({
          language: variables.language,
          groupId: variables.groupId,
        }),
        (oldData) => {
          return mergeDeepRight(oldData, {
            generatedVariances: {
              [variables.gender]: data.generatedVariances[variables.gender],
            },
          })
        }
      )
    },
  })

  return result
}

export const fetchSpeedTranslation = () =>
  buildRequest({
    path: 'admin/artificialtells/v2/group/translate',
    method: 'POST',
    buildPayload: ({ queryKey }) => ({
      groupId: queryKey[2].groupId,
      language: langDetectObjectsByType1[queryKey[2].language],
    }),
  })

export const useSpeedTranslationQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.speedTranslations(payload),
    queryFn: fetchSpeedTranslation(payload),
    refetchOnWindowFocus: false,
    staleTime: 15 * _.min,
    enabled: !!payload.groupId && !!payload.language,
  })

  return result
}

const fetchSpeedTranslationRegenerate = buildRequest({
  path: 'admin/artificialtells/v2/group/translate',
  method: 'POST',
  buildPayload: ({ groupId, prevResponseQuestions, language }) => {
    return {
      groupId,
      language: langDetectObjectsByType1[language],
      prevResponseQuestions,
    }
  },
})

export const useSpeedTranslationRegenerateMutation = () => {
  const queryClient = useQueryClient()

  const result = useMutation({
    mutationKey: artellv2.speedTranslationsRegenerate(),
    mutationFn: fetchSpeedTranslationRegenerate,
    onSuccess: (data, variables) => {
      queryClient.setQueryData(
        artellv2.speedTranslations({
          groupId: variables.groupId,
          language: variables.language,
        }),
        (oldData) => {
          return mergeDeepRight(oldData, {
            translated: {
              [variables.gender]: data.translated[variables.gender],
            },
          })
        }
      )
    },
  })

  return result
}

export const fetchTopics = () =>
  buildRequest({
    path: 'admin/artificialtells/v2/topics',
    method: 'GET',
    buildPayload: ({ queryKey }) => ({
      lang: langDetectObjectsByType1[queryKey[2].language],
      dataMode: queryKey[2].dataMode,
    }),
  })

export const useTopicsQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.allTopics(payload),
    queryFn: fetchTopics(payload),
    staleTime: 1 * _.h,
    refetchOnWindowFocus: false,
    retry: 3,
    onError: errorHandler,
  })

  return result
}

export const fetchProfileSharingDashboard = () =>
  buildRequest({
    path: 'admin/artificialtells/dashboard/sharing',
    method: 'GET',
    buildPayload: ({ queryKey }) => ({
      language: langDetectObjectsByType1[queryKey[2].language],
    }),
  })

export const useProfileSharingDashboardQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.profileSharingDashboard(payload),
    queryFn: fetchProfileSharingDashboard(payload),
    refetchOnWindowFocus: false,
    staleTime: 15 * _.min,
    onError: errorHandler,
  })

  return result
}

export const fetchWorkProgress = () =>
  buildRequest({
    path: 'admin/artificialtells/dashboard/workdone',
    method: 'GET',
    buildPayload: ({ queryKey }) => ({
      startDate: queryKey[2].startDate,
      endDate: queryKey[2].endDate,
    }),
  })

export const useWorkProgressQuery = (payload) => {
  const result = useQuery({
    queryKey: artellv2.workProgress(payload),
    queryFn: fetchWorkProgress(),
    refetchOnWindowFocus: false,
    staleTime: 15 * _.min,
    onError: errorHandler,
  })

  return result
}

const fetchTopicTags = () =>
  buildRequest({
    path: 'admin/artificialtells/v2/topic-tags',
    method: 'GET',
  })

export const useTopicTagsQuery = () => {
  const result = useQuery({
    queryKey: artellv2.tags,
    queryFn: fetchTopicTags(),
    refetchOnWindowFocus: false,
    staleTime: 15 * _.min,
    onError: errorHandler,
  })

  return result
}

const fetchAddTopicTag = buildRequest({
  path: 'admin/artificialtells/v2/topic-tags/create',
  method: 'POST',
})

export const useAddTopicTagMutation = () => {
  const result = useMutation({
    mutationKey: artellv2.tagsAdd(),
    mutationFn: fetchAddTopicTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: artellv2.tags })
      message.success('Tag added successfully')
    },
  })

  return result
}

const fetchRemoveTopicTag = buildRequest({
  path: 'admin/artificialtells/v2/topic-tags/remove',
  method: 'POST',
})

export const useRemoveTopicTagMutation = () => {
  const result = useMutation({
    mutationKey: artellv2.tagsRemove(),
    mutationFn: fetchRemoveTopicTag,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: artellv2.tags })
      message.success('Tag removed successfully')
    },
  })

  return result
}
