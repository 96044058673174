import React from 'react'
import { Switch } from 'react-router'
import { Router as BrowserRouter, Redirect } from 'react-router-dom'
import { PageArtificialTellsActivity } from '../../artificialTells/components/PageArtificialTellsActivity'
import { PageArtificialTellsAllGroups } from '../../artificialTells/components/PageArtificialTellsAllGroups'
import { PageArtificialTellsAnswersV2 } from '../../artificialTells/components/PageArtificialTellsAnswersV2'
import { PageArtificialTellsDashboard } from '../../artificialTells/components/PageArtificialTellsDashboard'
import { PageArtificialTellsGroupDetailsV2 } from '../../artificialTells/components/PageArtificialTellsGroupDetailsV2'
import { PageArtificialTellsGroupsOutperforming } from '../../artificialTells/components/PageArtificialTellsGroupsOutperforming'
import { PageArtificialTellsGroupsUnderperforming } from '../../artificialTells/components/PageArtificialTellsGroupsUnderperforming'
import { PageArtificialTellsPromptTool } from '../../artificialTells/components/PageArtificialTellsPromptTool'
import { PageArtificialTellsSpreadsheetParser } from '../../artificialTells/components/PageArtificialTellsSpreadsheetParser'
import { PageArtificialTellsTodos } from '../../artificialTells/components/PageArtificialTellsTodos'
import { PageArtificialTellsTopicContentSpeedGeneration } from '../../artificialTells/components/PageArtificialTellsTopicContentSpeedGeneration'
import { PageArtificialTellsTopicDetails } from '../../artificialTells/components/PageArtificialTellsTopicDetails'
import { PageArtificialTellsTopics } from '../../artificialTells/components/PageArtificialTellsTopics'
import { PageArtificialTellsValidation } from '../../artificialTells/components/PageArtificialTellsValidation'
import { PageArtificialTellsWorkProgress } from '../../artificialTells/components/PageArtificialTellsWorkProgress'
import { PageMarketSpeedTranslation } from '../../artificialTells/components/PageMarketSpeedTranslation'
import { PageArtificialTellsGroupsNotLocalized } from '../../artificialTells/components/PageNotLocalizedGroups'
import { PageProfileSharing } from '../../artificialTells/components/PageProfileSharing'
import { AntdModal, ContentContainer } from '../../common'
import { Alert } from '../../common/components/Alert'
import { Modal } from '../../common/components/Modal'
import { PopupModal } from '../../common/components/PopupModal'
import { RenderModal } from '../../common/components/RenderModal'
import { Text } from '../../common/components/Text'
import { View } from '../../common/components/View'
import { history } from '../../common/history'
import { PageLastBlocked } from '../../filter/components/PageLastBlocked'
import { PageLastDetectedSpam } from '../../filter/components/PageLastDetectedSpam'
import { PageSpamItemInfo } from '../../filter/components/PageSpamItemInfo'
import { Index } from '../../index/pages/Index'
import { PageAccountVerification } from '../../moderator/components/PageAccountVerification'
import { PageBanCandidates } from '../../moderator/components/PageBanCandidates'
import { PageBannedDevices } from '../../moderator/components/PageBannedDevices'
import { PageBlacklist } from '../../moderator/components/PageBlacklist'
import { PageModeration } from '../../moderator/components/PageModeration'
import { PageNsfwPictures } from '../../moderator/components/PageNsfwPictures'
import { PageResult } from '../../moderator/components/PageResult'
import { PageSearch } from '../../moderator/components/PageSearch'
import { PageChCompare } from '../../statistics/components/PageChCompare'
import { PageChDiff } from '../../statistics/components/PageChDiff'
import { PageClickhouseEvents } from '../../statistics/components/PageClickhouseEvents'
import { PageCsv } from '../../statistics/components/PageCsv'
import { PageUpload } from '../../statistics/components/PageUpload'
import { PageAnalyticsE2E } from '../../tools/components/PageAnalyticsE2E'
import { PageAnswersVisualiser } from '../../tools/components/PageAnswersVisualiser'
import { PageBadwordscore } from '../../tools/components/PageBadwordscore'
import { PageExperimentsTools } from '../../tools/components/PageExperimentsTools'
import { PageProfileVisualiser } from '../../tools/components/PageProfileVisualiser'
import { PageSpamScore } from '../../tools/components/PageSpamScore'
import { PageTiktokStats } from '../../tools/components/PageTiktokStats'
import { PageTiktokVideoGenerator } from '../../tools/components/PageTiktokVideoGenerator'
import { IfAuthenticated } from '../../user/components/IfAuthenticated'
import { IfLoaded } from '../../user/components/IfLoaded'
import { PageLogin } from '../../user/components/PageLogin'
import { Header } from './Header'
import { Route } from './Route'
import { SideMenu } from './SideMenu'

const NotFound = () => (
  <ContentContainer>
    <View
      style={{
        flex: 1,
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <Text center type="h1">
        {'Nothing here, yet ¯\\_(ツ)_/¯'}
      </Text>
    </View>
  </ContentContainer>
)

export const Router = () => (
  <IfLoaded>
    <BrowserRouter history={history}>
      <View style={{ height: '100%', flex: 1 }}>
        <Alert />
        <IfAuthenticated renderElse={() => <View />}>
          <Header />
          <RenderModal />
          <PopupModal />
          <AntdModal />
          <Modal />
        </IfAuthenticated>

        <SideMenu>
          <Switch>
            <Route
              exact
              path="/"
              authenticated={Index}
              unauthenticated={PageLogin}
            />
            <Route exact path="/auth/tiktok" authenticated={PageTiktokStats} />
            <Route
              exact
              path="/ban/candidates/:tab?"
              authenticated={PageBanCandidates}
            />
            <Route
              exact
              path="/ban/devices"
              authenticated={PageBannedDevices}
            />
            <Route
              exact
              path="/blacklist/:tab?"
              authenticated={PageBlacklist}
            />
            <Route exact path="/search/:type?" authenticated={PageSearch} />
            <Route exact path="/moderation" authenticated={PageModeration} />
            <Route
              exact
              path="/accountverification"
              authenticated={PageAccountVerification}
            />
            <Route
              exact
              path="/nsfwpictures"
              authenticated={PageNsfwPictures}
            />
            <Route exact path="/lastblocked" authenticated={PageLastBlocked} />
            <Route exact path="/spam" authenticated={PageLastDetectedSpam} />
            <Route
              exact
              path="/spam/:spamItemId"
              authenticated={PageSpamItemInfo}
            />
            <Redirect
              exact
              from="/artificialtells_v2"
              to="/artificialtells_v2/en/dashboard"
            />
            <Redirect
              exact
              from="/artificialtells_v2/dashboard"
              to="/artificialtells_v2/en/dashboard"
            />
            <Route
              exact
              path="/artificialtells_v2/:lang/dashboard"
              authenticated={PageArtificialTellsDashboard}
            />
            <Route
              exact
              path="/artificialtells_v2/workprogress"
              authenticated={PageArtificialTellsWorkProgress}
            />
            <Redirect
              exact
              from="/artificialtells_v2/profilesharing"
              to="/artificialtells_v2/en/profilesharing"
            />
            <Route
              exact
              path="/artificialtells_v2/:lang/profilesharing"
              authenticated={PageProfileSharing}
            />
            <Route
              exact
              path="/artificialtells_v2/allgroups"
              authenticated={PageArtificialTellsAllGroups}
            />
            <Route
              exact
              path="/artificialtells_v2/todos"
              authenticated={PageArtificialTellsTodos}
            />
            <Route
              exact
              path="/artificialtells_v2/activity"
              authenticated={PageArtificialTellsActivity}
            />
            <Redirect
              exact
              from="/artificialtells_v2/topics"
              to="/artificialtells_v2/en/topics/list"
            />
            <Redirect
              exact
              from="/artificialtells_v2/:lang/topics"
              to="/artificialtells_v2/:lang/topics/list"
            />
            <Route
              exact
              path="/artificialtells_v2/:language/topic/:topicId"
              authenticated={PageArtificialTellsTopicDetails}
            />
            <Route
              exact
              path="/artificialtells_v2/:language/topic/:topicId/content-speed-generation"
              authenticated={PageArtificialTellsTopicContentSpeedGeneration}
            />
            <Route
              exact
              path="/artificialtells_v2/:language/variance/:varianceId/answers"
              authenticated={PageArtificialTellsAnswersV2}
            />
            <Route
              exact
              path="/artificialtells_v2/:language/group/:groupId/answers"
              authenticated={PageArtificialTellsAnswersV2}
            />
            <Route
              exact
              path="/artificialtells_v2/:language/group/:groupId"
              authenticated={PageArtificialTellsGroupDetailsV2}
            />
            <Route
              exact
              path="/artificialtells_v2/:language/topics/:tab"
              authenticated={PageArtificialTellsTopics}
            />
            <Route
              exact
              path="/artificialtells_v2/:language/marketspeedtranslation"
              authenticated={PageMarketSpeedTranslation}
            />
            <Route
              exact
              path="/artificialtells_v2/:language/notlocalized"
              authenticated={PageArtificialTellsGroupsNotLocalized}
            />
            <Route
              exact
              path="/artificialtells_v2/:language/underperforming"
              authenticated={PageArtificialTellsGroupsUnderperforming}
            />
            <Route
              exact
              path="/artificialtells_v2/:language/outperforming"
              authenticated={PageArtificialTellsGroupsOutperforming}
            />
            <Route
              exact
              path="/artificialtells_v2/spreadsheetparser"
              authenticated={PageArtificialTellsSpreadsheetParser}
            />
            <Redirect
              exact
              from="/artificialtells_v2/validation"
              to="/artificialtells_v2/en/validation"
            />
            <Route
              exact
              path="/artificialtells_v2/:language/validation"
              authenticated={PageArtificialTellsValidation}
            />
            <Route exact path="/stats/ch/diff" authenticated={PageChDiff} />
            <Route
              exact
              path="/stats/ch/compare"
              authenticated={PageChCompare}
            />
            <Route
              exact
              path="/stats/chevents"
              authenticated={PageClickhouseEvents}
            />
            <Route exact path="/stats/csv" authenticated={PageCsv} />
            <Route exact path="/stats/upload" authenticated={PageUpload} />
            <Route
              exact
              path="/tools/analyticse2e"
              authenticated={PageAnalyticsE2E}
            />
            <Route
              exact
              path="/tools/badwordscore"
              authenticated={PageBadwordscore}
            />
            <Route
              exact
              path="/tools/spamscore"
              authenticated={PageSpamScore}
            />
            <Route
              exact
              path="/tools/experiments"
              authenticated={PageExperimentsTools}
            />
            <Route
              exact
              path="/tools/answersvisualiser"
              authenticated={PageAnswersVisualiser}
            />
            <Route
              exact
              path="/tools/profilevisualiser"
              authenticated={PageProfileVisualiser}
            />
            <Route
              exact
              path="/tools/generatetiktokvideo"
              authenticated={PageTiktokVideoGenerator}
            />
            <Route
              exact
              path="/tools/tiktokstats"
              authenticated={PageTiktokStats}
            />
            <Route
              exact
              path="/tools/artificialtellsprompts"
              authenticated={PageArtificialTellsPromptTool}
            />
            <Route exact path="/user/:id" authenticated={PageResult} />
            <Route component={NotFound} />
          </Switch>
        </SideMenu>
      </View>
    </BrowserRouter>
  </IfLoaded>
)
