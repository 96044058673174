import { TODO_ENTITY_TYPE, TODO_STATUS } from '@tellonym/enums/lib/Tell'
import { Breadcrumb, Button, Typography } from 'antd'
import qs from 'qs'
import React from 'react'
import * as ReactRedux from 'react-redux'
import {
  Box,
  BoxSecondary,
  ScrollView,
  TableEndlessScroll,
  View,
  _,
  history,
  hooks,
} from '../../common'
import { createTodo } from '../actionsV2'
import { useNotLocalizedGroupsQuery } from '../queries'
import {
  getDataMode,
  getGroupMode,
  getHeaderMode,
  getLanguage,
} from '../selectorsV2'
import { ButtonBack } from './ButtonBack'
import { MedianDataForDataMode } from './MedianDataForDataMode'
import { SelectorsLanguage } from './SelectorsLanguage'
import { SharedColumns, Stat, useColumnSearchProps } from './SharedColumns'

const Header = ({ language, refresh }) => {
  const onChangeDataMode = () => {
    refresh()
  }

  return (
    <BoxSecondary padding={24}>
      <Box
        alignItems="center"
        transparent
        flexDirection="row"
        paddingBottom={24}>
        <Box
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          transparent>
          <ButtonBack
            onPress={() => {
              history.goBack()
            }}
            style={{ marginRight: 12, width: 30, height: 30 }}
          />

          <Breadcrumb>
            <Breadcrumb.Item key="Not localized">
              Not localized Groups
            </Breadcrumb.Item>
          </Breadcrumb>
        </Box>
      </Box>

      <Box flexDirection="row" justifyContent="space-between" transparent>
        <Box transparent />

        <Box transparent marginRight={48}>
          <MedianDataForDataMode
            data={[]}
            hasTitle
            onChange={onChangeDataMode}
          />
        </Box>

        <SelectorsLanguage />
      </Box>
      <Typography.Text type="secondary" style={{ textAlign: 'right' }}>
        Answer rate data is from DE, in DE it is from EN.
      </Typography.Text>
    </BoxSecondary>
  )
}

const TranslateAction = ({
  topicId,
  isOptimisticallyPending,
  isPending,
  onPressTranslate,
}) => {
  const onPress = (e) => {
    e.stopPropagation()

    onPressTranslate(topicId)
  }

  return (
    <Button
      size="small"
      disabled={isOptimisticallyPending || isPending}
      type="primary"
      onClick={onPress}>
      {isOptimisticallyPending || isPending ? 'Pending' : 'Translate'}
    </Button>
  )
}
export const PageArtificialTellsGroupsNotLocalized = () => {
  const table = hooks.useTableState()

  const dispatch = ReactRedux.useDispatch()

  const dataMode = ReactRedux.useSelector(getDataMode)
  const groupMode = ReactRedux.useSelector(getGroupMode)
  const headerMode = ReactRedux.useSelector(getHeaderMode)
  const language = ReactRedux.useSelector(getLanguage)

  const searchPropsGroupName = useColumnSearchProps('name')
  const searchPropsTopicName = useColumnSearchProps('topicName')

  const { data, refetch, isLoading } = useNotLocalizedGroupsQuery({
    language,
    dataMode,
  })

  const [optimisticallyPendingTopicIds, setOptimisticallyPendingTopicIds] =
    React.useState([])

  const onPressTranslate = React.useCallback(
    (topicId) => {
      setOptimisticallyPendingTopicIds((prev) => [...prev, topicId])

      dispatch(
        createTodo({
          status: TODO_STATUS.OPEN,
          content: 'Translation needed',
          dueInXDays: 7,
          language,
          referenceId: topicId,
          type: TODO_ENTITY_TYPE.TOPIC,
        })
      )
    },
    [dispatch, language]
  )

  const datasource = React.useMemo(() => {
    return data?.groups.map((group) => ({
      ...group,
      isOptimisticallyPending: optimisticallyPendingTopicIds.includes(
        group.topicId
      ),
    }))
  }, [data?.groups, optimisticallyPendingTopicIds])

  const columns = React.useMemo(
    () => [
      SharedColumns.id,
      {
        ...SharedColumns.status,
        defaultFilteredValue: null,
        defaultSortOrder: null,
      },
      { ...SharedColumns.groupDepth, defaultSortOrder: null },
      { ...SharedColumns.groupType, defaultSortOrder: null },
      {
        title: 'Topic name',
        dataIndex: 'topicName',
        key: 'topicName',
        width: 280,
        sorter: (a, b) => {
          const textA = a.name
          const textB = b.name

          if (textA < textB) {
            return -1
          }

          if (textA > textB) {
            return 1
          }

          return 0
        },
        ...searchPropsTopicName,
        render: (topicName) => <View>{topicName}</View>,
      },
      {
        title: 'Question',
        dataIndex: 'id',
        key: 'name',
        width: 280,
        render: (id, group) => <View>{group.name}</View>,
        ...searchPropsGroupName,
        sorter: (a, b) => {
          const textA = a.name?.toLowerCase()
          const textB = b.name?.toLowerCase()

          if (textA < textB) {
            return -1
          }

          if (textA > textB) {
            return 1
          }

          return 0
        },
      },
      {
        ...SharedColumns.groupTotalSent,
        defaultSortOrder: null,
        render: (id, group) =>
          SharedColumns.groupTotalSent.render(id, {
            ...group,
            status: 'override', // Setting an arbitrary status so the render function returns a value
          }),
      },
      {
        title: 'Answer Rate',
        dataIndex: 'id',
        key: 'answerRate',
        width: 120,
        defaultSortOrder: 'descend',
        sorter: (a, b) =>
          a.analytics.answerRate.amount - b.analytics.answerRate.amount,
        render: (id, group) => {
          return (
            <Stat
              isPercentage
              value={group.analytics.answerRate.amount}
              change={group.analytics.answerRate.change}
            />
          )
        },
      },
      {
        title: 'Actions',
        dataIndex: 'id',
        key: 'isPendingTranslation',
        width: 80,
        filters: [
          {
            text: 'Open',
            value: 'open',
          },
          {
            text: 'Pending',
            value: 'pending',
          },
        ],
        filterMultiple: true,
        filterResetToDefaultFilteredValue: true, // click the reset button, whether to restore the default filter
        defaultFilteredValue: ['open'],
        onFilter: (value, group) => {
          switch (value) {
            case 'open':
              return group.isPendingTranslation === false

            case 'pending':
              return group.isPendingTranslation === true

            default:
              return true
          }
        },
        render: (id, group) => {
          return (
            <TranslateAction
              onPressTranslate={onPressTranslate}
              topicId={group.topicId}
              isOptimisticallyPending={group.isOptimisticallyPending}
              isPending={group.isPendingTranslation}
            />
          )
        },
      },
    ],
    [onPressTranslate, searchPropsGroupName, searchPropsTopicName]
  )

  const onRow = React.useCallback(
    (group) => {
      const queryString = qs.stringify({ groupMode, headerMode })

      const routeProps = _.openRouteProps(
        `/artificialtells_v2/${language ?? 'en'}/group/${
          group.id
        }?${queryString}`
      )

      return { onClick: routeProps.onPress, onAuxClick: routeProps.onAuxClick }
    },
    [language, groupMode, headerMode]
  )

  return (
    <Box>
      <Header language={language} refresh={refetch} />
      <ScrollView>
        <TableEndlessScroll
          {...table}
          hasSmallRows
          hasZebraRows
          rowKey="id"
          loading={isLoading}
          dataSource={datasource}
          language={language}
          columns={columns}
          onRow={onRow}
        />
      </ScrollView>
    </Box>
  )
}
